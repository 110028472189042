import consts from "./consts";
export default {
  getMemberUsers: consts.apiBasicUrl + "/users",
  getMemberAdmins: consts.apiBasicUrl + "/admins",
  deleteAdmin: (id) => consts.apiBasicUrl + "/admins/" + id,
  putAdmin: (id) => consts.apiBasicUrl + "/admins/" + id,
  insertAdmin: consts.apiBasicUrl + "/admins",
  getMemberUser: (id) => consts.apiBasicUrl + "/users/" + id,
  getNotices: consts.apiBasicUrl + "/notices",
  getNotice: (id) => consts.apiBasicUrl + "/notices/" + id,
  deleteNotice: (id) => consts.apiBasicUrl + "/notices/" + id,
  insertNotice: consts.apiBasicUrl + "/notices",
  putNotice: (id) => consts.apiBasicUrl + "/notices/" + id,
  getNotification: consts.apiBasicUrl + "/notifications",
  insertNotification: consts.apiBasicUrl + "/notifications",
  deleteNotification: (id) => consts.apiBasicUrl + "/notifications/" + id,
  getInquiries: consts.apiBasicUrl + "/inquiries",
  replyInquiry: (id) => consts.apiBasicUrl + "/inquiries/" + id + "/reply",
  getCategories: consts.apiBasicUrl + "/categories",

  getRecommendations: consts.apiBasicUrl + "/recommendations",
  insertRecommendation: consts.apiBasicUrl + "/recommendations",
  putRecommendation: (id) => consts.apiBasicUrl + "/recommendations/" + id,
  deleteRecommendation: (id) => consts.apiBasicUrl + "/recommendations/" + id,

  getProducts: consts.apiBasicUrl + "/products",
  insertProduct: consts.apiBasicUrl + "/products",
  putProduct: (id) => consts.apiBasicUrl + "/products/" + id,
  deleteProduct: (id) => consts.apiBasicUrl + "/products/" + id,

  signIn: consts.apiBasicUrl + "/admins/signin",
  verifyToken: consts.apiBasicUrl + "/admins/token",
  restoreUser: (uid) => consts.apiBasicUrl + "/users/" + uid + "/restore",
  getProfiles: (uid) => consts.apiBasicUrl + "/users/" + uid + "/profiles",
  getHairInspections: consts.apiBasicUrl + "/inspections/hairs",
};
