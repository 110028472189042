import React from "react";
import { makeStyles, Button } from "@material-ui/core";
import { getMomentBeforeDay, getToday } from "../../libs/utils";
import queryString from "query-string";

const TableFilterButtons = ({ data, field, query, history, location }) => {
  const classes = useStyle();
  return (
    <div className={classes.root}>
      {[{ label: "전체", value: undefined }, ...data].map((x, i) => {
        const isSelected =
          (x.value ? x.value.toString() : x.value) === query[field]
            ? classes.buttonsS
            : "";
        return (
          <Button
            onClick={() => {
              if (x.value === undefined) {
                delete query[field];
                history.replace({
                  pathname: location.pathname,
                  search: queryString.stringify({
                    ...query,
                  }),
                });
              } else {
                history.replace({
                  pathname: location.pathname,
                  search: queryString.stringify({
                    ...query,
                    [field]: x.value,
                  }),
                });
              }
            }}
            className={classes.button + "  " + isSelected}
            key={i.toString()}
            variant="outlined"
          >
            {x.label}
          </Button>
        );
      })}
    </div>
  );
};

const useStyle = makeStyles({
  root: {
    marginTop: "8px",
    display: "flex",
    alignItems: "center",
    alignSelf: "stretch",
    justifyContent: "flex-end",
  },
  button: {
    marginLeft: "8px",
  },
  buttonsS: {
    borderColor: "#3f51b5",
  },
});

export default React.memo(TableFilterButtons);
