import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import DetailLayout from "../../layouts/detail/DetailLayout";
import MultiLangForm from "../../components/multi-lang-form/MultiLangForm";
import { requestDelete, requestPost, requestPut } from "../../libs/network";
import consts from "../../libs/consts";
import { dialogOpenError } from "../../redux/dialog/DialogActions";
import Radios from "../../components/radios/Radios";
import { Button, makeStyles } from "@material-ui/core";
import HairQuestionnaireComment from "./HairQuestionnaireComment";

export default function HairQuestionnaireDetail({ location, history }) {
  const dispatch = useDispatch();
  const classes = useStyle();
  const [hairQuestionnaireId, setHairQuestionnaireId] = useState(null);
  const [langData, setLangData] = useState();
  const langFormRef = useRef();
  const [multiple, setMultiple] = useState("0");
  const [answerForms, setAnswerForms] = useState([]);
  const answerLangFormRef = useRef();
  const [answerLangData, setAnswerLangData] = useState();
  const [dialog, setDialog] = useState(false);

  useEffect(() => {
    if (location.state?.hairQuestionnaireId) {
      setHairQuestionnaireId(location.state?.hairQuestionnaireId);
      setLangData(location.state.translations);
      setMultiple(location.state.multiple === 1 ? "1" : "0");
      if (location.state.answers && location.state.answers.length > 0) {
        const answerForms = location.state.answers.map((x, i) => {
          const langCategoryIds = [];
          const allTrans = [];
          for (let i = 0; i < x.comments.length; i++) {
            const commentTrans = x.comments[i].translations;
            allTrans.push(...commentTrans);
            commentTrans.map((x, i) => {
              if (!langCategoryIds.includes(x.langCodeCategoryId)) {
                langCategoryIds.push(x.langCodeCategoryId);
              }
            });
          }
          const commentData = langCategoryIds.map((x, i) => {
            const result = {
              langCodeCategoryId: x,
            };
            allTrans
              .filter((y) => x === y.langCodeCategoryId)
              .forEach((d, y) => {
                result[y + 1] = d.label;
                result["summary-" + (y + 1)] = d.summary;
              });
            return result;
          });
          return {
            type: "input",
            key: (i + 1).toString(),
            maxLength: 100,
            label: " ",
            wrap: true,
            comments: commentData,
          };
        });
        const langCategoryIds = [];
        const allTrans = [];
        for (let i = 0; i < answerForms.length; i++) {
          const answertranslations = location.state.answers[i].translations;
          allTrans.push(...answertranslations);
          answertranslations.map((x, i) => {
            if (!langCategoryIds.includes(x.langCodeCategoryId)) {
              langCategoryIds.push(x.langCodeCategoryId);
            }
          });
        }
        setAnswerLangData(
          langCategoryIds.map((x, i) => {
            const result = {
              langCodeCategoryId: x,
            };
            allTrans
              .filter((y) => x === y.langCodeCategoryId)
              .forEach((d, y) => {
                result[y + 1] = d.label;
              });
            return result;
          })
        );
        setAnswerForms([...answerForms]);
      }
    }
  }, []);

  const handleConfirm = async () => {
    if (langFormRef.current.check() && answerLangFormRef.current.check()) {
      try {
        const labels = langData;
        const answers = answerLangData.map((x) => {
          const keys = Object.keys(x).filter((x) => x !== "langCodeCategoryId");
          const labels = keys.map((key) => x[key]);
          return {
            langCodeCategoryId: x.langCodeCategoryId,
            labels,
          };
        });
        const answerComments = answerForms.map((_) => {
          return _.comments.map((x) => {
            const keys = Object.keys(x).filter(
              (x) => x !== "langCodeCategoryId"
            );

            const valueKeys = [];
            const summaryKeys = [];

            keys.forEach((key) => {
              if (key.startsWith("summary-")) {
                summaryKeys.push(key);
              } else {
                valueKeys.push(key);
              }
            });

            const labels = valueKeys.map((key) => x[key]);
            const summaries = summaryKeys.map((key) => x[key]);

            return {
              langCodeCategoryId: x.langCodeCategoryId,
              labels,
              summaries,
            };
          });
        });

        if (hairQuestionnaireId) {
          await requestPut({
            url: consts.apiBasicUrl + "/questionnaires/" + hairQuestionnaireId,
            body: {
              labels,
              answers,
              answerComments,
              multiple: String(multiple) === "1",
            },
          });
        } else {
          await requestPost({
            url: consts.apiBasicUrl + "/questionnaires",
            body: {
              labels,
              answers,
              answerComments,
              multiple: String(multiple) === "1",
            },
          });
        }
        history.goBack();
      } catch (error) {
        dispatch(dialogOpenError(error));
      }
    }
  };

  const deleteItem = async () => {
    try {
      await requestDelete({
        url: consts.apiBasicUrl + "/questionnaires/" + hairQuestionnaireId,
      });
      history.goBack();
    } catch (error) {
      dispatch(dialogOpenError(error));
    }
  };

  return (
    <DetailLayout
      onDelete={hairQuestionnaireId && deleteItem}
      buttonConfirmLabel={hairQuestionnaireId ? "수정" : "등록"}
      title={hairQuestionnaireId ? "검사 설문 수정" : "검사  설문 등록"}
      onConfirm={handleConfirm}
    >
      <MultiLangForm
        label="설문 제목"
        onDataChange={setLangData}
        ref={langFormRef}
        forms={[
          {
            type: "input",
            label: "설문 제목",
            key: "label",
            maxLength: 100,
            required: true,
          },
        ]}
        data={langData}
      />

      <MultiLangForm
        options={
          <React.Fragment>
            <Button
              className={classes.button}
              color="primary"
              variant="outlined"
              onClick={() => {
                const addKey = (
                  (answerForms[answerForms.length - 1]
                    ? parseInt(answerForms[answerForms.length - 1].key)
                    : 0) + 1
                ).toString();
                setAnswerForms([
                  ...answerForms,
                  {
                    type: "input",
                    key: addKey,
                    maxLength: 100,
                    label: " ",
                    wrap: true,
                    comments: [],
                  },
                ]);
              }}
            >
              답안 추가
            </Button>
          </React.Fragment>
        }
        label="설문 답안 입력"
        onDataChange={setAnswerLangData}
        ref={answerLangFormRef}
        forms={answerForms}
        data={answerLangData}
        answer={true}
        button={{
          label: "코멘트 등록",
          onClick: (data) => {
            setDialog({ ...data });
          },
        }}
        onFormDelete={(form) => {
          let filterKey;
          setAnswerForms([
            ...answerForms.filter((x, y) => {
              if (x.key !== form.key) {
                return true;
              } else {
                filterKey = x.key;
                return false;
              }
            }),
          ]);
          setAnswerLangData([
            ...answerLangData.map((x) => {
              delete x[filterKey];
              return x;
            }),
          ]);
        }}
      />

      <Radios
        value={multiple}
        onChange={setMultiple}
        label="설문 답안 중복체크"
        data={[
          { label: "예", value: "1" },
          { label: "아니요", value: "0" },
        ]}
      />
      {Boolean(dialog) && (
        <HairQuestionnaireComment
          onClose={() => {
            setDialog(null);
          }}
          onSave={(t) => {
            setAnswerForms([
              ...answerForms.map((x) => {
                if (x.key === dialog.key) {
                  x.comments = t;
                }
                return x;
              }),
            ]);
          }}
          {...dialog}
        />
      )}
    </DetailLayout>
  );
}

const useStyle = makeStyles({
  form: {
    marginTop: "40px",
  },
  button: { marginLeft: "16px" },
  tabsSencond: {
    borderBottom: "1px solid gray",
  },
  button2: {
    margin: "15px 0px",
  },
});
