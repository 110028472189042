import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import { dialogOpenMessage } from "../../redux/dialog/DialogActions";
import consts from "../../libs/consts";

const FileSelectorAdd = ({ acceptImage, acceptDoc, onAdd }) => {
  const dispatch = useDispatch();
  const classes = useStyle();
  const inputRef = useRef();
  const [height, setHeight] = useState(0);

  const handleChange = (e) => {
    if (!e.target.files[0]) {
      return;
    }
    const file = e.target.files[0];
    if (
      acceptImage &&
      acceptDoc &&
      !file.type.includes("image") &&
      !consts.docTypes.includes("." + file.name.split(".").pop())
    ) {
      dispatch(
        dialogOpenMessage({
          message:
            "이미지 파일과 " +
            consts.docTypes.join(", ") +
            " 파일만 등록 가능합니다",
        })
      );
      return;
    } else if (
      acceptDoc &&
      !acceptImage &&
      !consts.docTypes.includes("." + file.name.split(".").pop())
    ) {
      dispatch(
        dialogOpenMessage({
          message: consts.docTypes.join(", ") + " 파일만 등록 가능합니다",
        })
      );
      return;
    } else if (!acceptDoc && acceptImage && !file.type.includes("image")) {
      dispatch(
        dialogOpenMessage({
          message: "이미지파일만 등록 가능합니다",
        })
      );
      return;
    }
    const fs = new FileReader();
    fs.onloadend = () => {
      file.uri = fs.result;
      onAdd({
        uri: fs.result,
        name: file.name,
        size: file.size,
        type: file.type,
      });
    };
    fs.readAsDataURL(file);

    e.target.value = "";
  };
  return (
    <div className={classes.root}>
      <div
        onClick={() => {
          inputRef.current.click();
        }}
        ref={(ref) => {
          if (ref) {
            setHeight(ref.offsetWidth);
          }
        }}
        className={classes.item}
      >
        <Add />
      </div>
      <input
        onChange={handleChange}
        ref={inputRef}
        type="file"
        style={{ display: "none" }}
      />
    </div>
  );
};

const useStyle = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "20%",
    height: "100%",
    padding: "0px 10px 10px 0px",
  },
  item: {
    cursor: "pointer",
    border: "1px solid rgba(0,0,0,0.13)",
    borderRadius: "5px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
});

export default FileSelectorAdd;
