import React, { useState, useEffect } from "react";
import { makeStyles, TextField, Button } from "@material-ui/core";

const EmailForm = ({ value, className, onClick, emails }) => {
  const classes = useStyle();
  const [email, setEmail] = useState("");
  const [input, setInput] = useState("");

  useEffect(() => {
    if (value) {
      setInput(value);
    }
  }, [value]);
  return (
    <div className={classes.root + " " + className}>
      <textarea
        value={input}
        onChange={(e) => {
          setInput(e.target.value);
        }}
        readOnly={Boolean(value)}
        className={classes.textarea}
      />

      <div className={classes.footer}>
        <span className={classes.emails}>{emails && `전송됨 : ${emails}`}</span>
        <Button
          onClick={() => {
            onClick(input);
          }}
          disabled={!input || Boolean(value)}
          variant="outlined"
          className={classes.button}
        >
          {Boolean(value) ? "전송됨" : "메일 전송"}
        </Button>
      </div>
    </div>
  );
};

const useStyle = makeStyles({
  root: {
    display: "flex",
    alignSelf: "stretch",
    flexDirection: "column",
  },
  textarea: {
    display: "flex",
    alignSelf: "stretch",
    borderRadius: "5px",
    border: "1px solid rgba(0,0,0,0.13)",
    padding: "12px",
    fontSize: "14px",
    height: "auto",
    minHeight: "200px",
    fontFamily: "roboto",
  },
  button: {
    padding: "10px 30px",
  },
  footer: {
    alignSelf: "stretch",
    display: "flex",
    alignItems: "center",
    marginTop: "20px",
    justifyContent: "space-between",
  },
  emails: {
    display: "flex",
    flexDirection: "row",
  },
});

export default EmailForm;
