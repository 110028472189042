import React, { useState } from "react";
import Table from "../../components/table/Table";
import routes from "../../libs/routes";
import { useDispatch } from "react-redux";
import { dialogOpenError } from "../../redux/dialog/DialogActions";
import { requestGet, requestPost } from "../../libs/network";
import api from "../../libs/api";
import { getQueryObj } from "../../libs/utils";
const MemberDelete = ({ history, location }) => {
  const [maxLength, setMaxLength] = useState(0);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const query = getQueryObj(location);

  const fetchNormalUsers = async () => {
    try {
      const qr = {
        ...query,
        isDeleted: 1,
        deleteDateStart: query.dateStart,
        deleteDateEnd: query.dateEnd,
        type: "user",
      };
      delete qr.dateStart;
      delete qr.dateEnd;

      const { users, maxLength } = await requestGet({
        url: api.getMemberUsers,
        query: qr,
      });
      setMaxLength(maxLength);
      setData([...users]);
    } catch (error) {
      dispatch(dialogOpenError(error));
    }
  };

  const restore = async () => {
    try {
      const user = data.filter((x) => x.radio)[0];
      if (!user) {
        throw "복구할 회원을 선택해주세요.";
      }
      await requestPost({ url: api.restoreUser(user.userId) });
      history.go(0);
    } catch (error) {
      dispatch(dialogOpenError(error));
    }
  };

  return (
    <div>
      <Table
        onDataChange={setData}
        radio
        title="탈퇴회원 목록"
        onFetch={fetchNormalUsers}
        maxLength={maxLength}
        data={data}
        searchPlaceholder="이름, 이메일, 탈퇴일"
        buttons={[{ label: "복구", onClick: restore }]}
        columns={[
          {
            label: "이름",
            key: "name",
          },
          {
            label: "이메일",
            key: "email",
          },
          {
            label: "가입유형",
            key: "methodLabel",
          },
          {
            label: "가입일",
            key: "createdAt",
            type: "datetime",
          },
          {
            label: "탈퇴일",
            key: "deletedAt",
            type: "datetime",
          },
        ]}
        filters={[
          {
            key: "method",
            data: [
              { label: "자체회원", value: "email" },
              { label: "구글", value: "google" },
              { label: "애플", value: "apple" },
              { label: "페이스북", value: "facebook" },
            ],
          },
        ]}
      />
    </div>
  );
};

export default MemberDelete;
