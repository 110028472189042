import React, { useEffect, useState, useRef } from "react";
import MultiLangForm from "../../components/multi-lang-form/MultiLangForm";
import { makeStyles } from "@material-ui/core";
import FileSelector from "../../components/file-selector/FileSelector";
import { useDispatch } from "react-redux";
import DetailLayout from "../../layouts/detail/DetailLayout";
import { dialogOpenError } from "../../redux/dialog/DialogActions";
import {
  requestPost,
  requestFile,
  requestGet,
  requestDelete,
  requestPut,
} from "../../libs/network";
import api from "../../libs/api";
import { setFormArray, setFormFiles } from "../../libs/utils";
import consts from "../../libs/consts";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { dataFetch } from "../../redux/data/DataActions";

const ProductCategoryDetail = ({ history, location }) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const langFormRef = useRef();
  const [productCategoryId, setProductCategoryId] = useState(null);
  const [langData, setLangData] = useState();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (location.state?.productCategoryId) {
      setProductCategoryId(location.state?.productCategoryId);
      setLangData(location.state.translations);
    }
  }, []);

  const handleConfirm = async () => {
    if (langFormRef.current.check()) {
      try {
        if (productCategoryId) {
          await requestPut({
            url: consts.apiBasicUrl + "/productcategories/" + productCategoryId,
            body: { translations: langData },
          });
        } else {
          await requestPost({
            url: consts.apiBasicUrl + "/productcategories",
            body: { translations: langData },
          });
        }
        dispatch(dataFetch);
        history.goBack();
      } catch (error) {
        dispatch(dialogOpenError(error));
      }
    }
  };

  const deleteNotice = async () => {
    try {
      await requestDelete({
        url: consts.apiBasicUrl + "/productcategories/" + productCategoryId,
      });
      history.goBack();
    } catch (error) {
      dispatch(dialogOpenError(error));
    }
  };

  return (
    <DetailLayout
      onDelete={productCategoryId && handleClickOpen}
      buttonConfirmLabel={productCategoryId ? "수정" : "등록"}
      title={productCategoryId ? "상품 카테고리 수정" : "상품 카테고리 등록"}
      onConfirm={handleConfirm}
    >
      {
        //---
      }
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"삭제하시겠습니까?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            연동된 모든 상품이 삭제됩니다. 삭제전 연동된 상품의 카테고리를
            변경해주세요.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            취소
          </Button>
          <Button onClick={deleteNotice} color="primary" autoFocus>
            삭제
          </Button>
        </DialogActions>
      </Dialog>

      {
        //---
      }
      <MultiLangForm
        onDataChange={setLangData}
        ref={langFormRef}
        forms={[
          {
            type: "input",
            label: "카테고리 명",
            key: "name",
            maxLength: 30,
            required: true,
          },
        ]}
        data={langData}
      />
    </DetailLayout>
  );
};

const useStyle = makeStyles({});
export default ProductCategoryDetail;
