import queryString from "query-string";
import axios from "axios";
import { getItem } from "./utils";
import consts from "./consts";

export const requestFile = async ({ url, form, headers, method = "post" }) => {
  try {
    const func =
      method === "post"
        ? axios.post
        : method === "put"
        ? axios.put
        : axios.patch;

    const response = await func(url, form, {
      headers: {
        ...headers,
        "content-type": "multipart/form-data",
        authorization: `CandyIronAdmin ${getItem(consts.loginTokenKey)}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      const { data } = error.response;
      let errorObj;
      if (typeof data === "string") {
        errorObj = { message: data };
      } else {
        errorObj = data;
      }
      throw errorObj;
    }
  }
};

export const requestPost = async ({ url, body = {}, headers }) => {
  try {
    const response = await axios.post(url, body, {
      headers: {
        ...headers,
        authorization: `CandyIronAdmin ${getItem(consts.loginTokenKey)}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      const { data } = error.response;
      let errorObj;
      if (typeof data === "string") {
        errorObj = { message: data };
      } else {
        errorObj = data;
      }
      throw errorObj;
    }
  }
};

export const requestDelete = async ({ url, headers, query = {} }) => {
  try {
    const qs = queryString.stringify(query);

    const response = await axios.delete(`${url}?${qs}`, {
      headers: {
        ...headers,
        authorization: `CandyIronAdmin ${getItem(consts.loginTokenKey)}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      const { data } = error.response;
      let errorObj;
      if (typeof data === "string") {
        errorObj = { message: data };
      } else {
        errorObj = data;
      }
      throw errorObj;
    }
  }
};

export const requestPut = async ({ url, body = {}, headers }) => {
  try {
    const response = await axios.put(url, body, {
      headers: {
        ...headers,
        authorization: `CandyIronAdmin ${getItem(consts.loginTokenKey)}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      const { data } = error.response;
      let errorObj;
      if (typeof data === "string") {
        errorObj = { message: data };
      } else {
        errorObj = data;
      }
      throw errorObj;
    }
  }
};

export const requestGet = async ({ url, headers, query = {} }) => {
  try {
    const qs = queryString.stringify(query);
    const response = await axios.get(`${url}?${qs}`, {
      headers: {
        ...headers,
        authorization: `CandyIronAdmin ${getItem(consts.loginTokenKey)}`,
      },
    });
    return response.data;
  } catch (error) {
    let errorObj;
    if (error.response) {
      const { data } = error.response;
      if (typeof data === "string") {
        errorObj = { message: data };
      } else {
        errorObj = data;
      }
    } else {
      errorObj = error;
    }
    throw errorObj;
  }
};

export const requestPatch = async ({ url, body = {}, headers }) => {
  try {
    const response = await axios.patch(url, body, {
      headers: {
        ...headers,
        authorization: `CandyIronAdmin ${getItem(consts.loginTokenKey)}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      const { data } = error.response;
      let errorObj;
      if (typeof data === "string") {
        errorObj = { message: data };
      } else {
        errorObj = data;
      }
      throw errorObj;
    }
  }
};
