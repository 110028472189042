import React from "react";
import { Dialog, makeStyles } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { dialogClose } from "../../redux/dialog/DialogActions";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const DialogMessage = ({}) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const { message } = useSelector((s) => s.dialog, []);

  const handleClose = () => {
    dispatch(dialogClose);
  };

  return (
    <Dialog
      style={{
        zIndex: 1400,
      }}
      open={message.open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        message.close && handleClose();
      }}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      {message.message && (
        <DialogTitle id="alert-dialog-slide-message">
          {message.message}
        </DialogTitle>
      )}

      <DialogActions>
        <Button onClick={handleClose} color="primary">
          확인
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyle = makeStyles({
  root: {
    backgroundColor: "rgba(0,0,0,0.1)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
});

export default DialogMessage;
