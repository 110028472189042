export default {
  signIn: "/signin",
  main: "/",
  notFound: "/404",
  badRequest: "/400",
  member: "/member",
  memberUser: "/member/user",
  memberAdminDetail: "/member/admin/detail",
  memberAdmin: "/member/admin",
  memberDelete: "/member/exit",
  memberDetail: "/member/user/detail",
  inspection: "/inspection",
  inspectionHair: "/inspection/hair",

  inspectResult: "/inspectresult",
  hairQuestionnaire: "/inspectresult/hairquestionnaire",
  hairQuestionnaireDetail: "/inspectresult/hairquestionnaire/detail",
  inspectResultHabit: "/inspectresult/comment",
  inspectResultHabitDetail: "/inspectresult/comment/detail",

  inspectionHairDetail: "/inspection/hair/detail",
  board: "/board",
  boardNotice: "/board/notice",
  boardNoticeDetail: "/board/notice/detail",
  qna: "/board/qna",
  qnaDetail: "/board/qna/detail",
  boardInquiry: "/board/inquiry",
  boardInquiryDetail: "/board/inquiry/detail",
  boardNotification: "/board/notification",
  boardNotificationDetail: "/board/notification/detail",
  recommendation: "/recommendation",
  recommendationProductDetail: "/recommendation/product/detail",
  recommendationProduct: "/recommendation/product",
  recommendationProductCategory: "/recommendation/procategory",
  recommendationProductCategoryDetail: "/recommendation/procategory/detail",

  data: "/data",
  dataPolicy: "/data/policy",
};
