import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MultiLangForm from "../../components/multi-lang-form/MultiLangForm";

const defaultForm = {
  maxLength: 300,
  label: "",
  type: "input-summary",
};

export default function HairQuestionnaireComment({
  onClose,
  comments,
  onSave,
}) {
  const classes = useStyles();
  const langRef = useRef();
  const [tempData, setTempData] = useState();
  const [tempForms, setTempForms] = useState([]);

  useEffect(() => {
    if (comments && comments.length > 0) {
      setTempData([...comments]);
      const length = Object.keys(comments[0]).filter(
        (x) => x !== "langCodeCategoryId" && !x.startsWith("summary-")
      ).length;
      const forms = [];
      for (let i = 0; i < length; i++) {
        forms.push({
          key: (i + 1).toString(),
          ...defaultForm,
        });
      }

      setTempForms([...forms]);
    }
  }, [comments]);

  const handleClose = () => {
    onClose();
  };

  const save = () => {
    if (langRef.current.check()) {
      onSave(tempData);
      onClose();
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth={"md"}
      open
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle id="max-width-dialog-title">코멘트 등록</DialogTitle>
      <span style={{ marginLeft: "16px", color: "red", fontSize: "14px" }}>
        * 가장 첫 문장(ex: "~.")은 앱에서 강조표시 되며 뒷 내용과 분리됩니다.
        <br />
        유의해서 작성해주세요.
      </span>
      <DialogContent>
        <MultiLangForm
          options={
            <Button
              className={classes.button}
              color="primary"
              variant="outlined"
              onClick={() => {
                const addKey = (
                  (tempForms[tempForms.length - 1]
                    ? parseInt(tempForms[tempForms.length - 1].key)
                    : 0) + 1
                ).toString();
                setTempForms([
                  ...tempForms,
                  {
                    ...defaultForm,
                    key: addKey,
                  },
                ]);
              }}
            >
              코멘트 추가
            </Button>
          }
          label="코멘트 입력"
          onDataChange={setTempData}
          ref={langRef}
          forms={tempForms}
          data={tempData}
          onFormDelete={(form) => {
            let filterKey;
            let filterIndex;
            setTempForms([
              ...tempForms.filter((x, y) => {
                if (x.key !== form.key) {
                  return true;
                } else {
                  filterIndex = y;
                  filterKey = x.key;
                  return false;
                }
              }),
            ]);
            setTempData([
              ...tempData.map((x) => {
                delete x[filterKey];
                return x;
              }),
            ]);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          취소
        </Button>
        <Button onClick={save} color="primary">
          저장
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: "16px",
  },
}));
