import {
  FormControl,
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import React from "react";

export default function Radios({
  data,
  value,
  onChange,
  className,
  label,
  row = true,
}) {
  const classes = useStyle();
  return (
    <FormControl
      component="fieldset"
      className={classes.form + " " + className}
    >
      <h3>{label}</h3>
      <RadioGroup
        row={row}
        color="primary"
        aria-label="type"
        name="types"
        value={value}
        onChange={(event) => {
          onChange(event.target.value);
        }}
      >
        {data.map((x, i) => (
          <FormControlLabel
            value={x.value}
            control={<Radio color="primary" />}
            label={x.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

const useStyle = makeStyles({});
