import React, { useState } from "react";
import Table from "../../components/table/Table";
import routes from "../../libs/routes";
import { useDispatch } from "react-redux";
import { dialogOpenError } from "../../redux/dialog/DialogActions";
import { requestGet, requestPatch } from "../../libs/network";
import { getQueryObj } from "../../libs/utils";
import consts from "../../libs/consts";
import { Button } from "@material-ui/core";
const ProductCategory = ({ history, location }) => {
  const [maxLength, setMaxLength] = useState(0);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const query = getQueryObj(location);

  const fetchProductCategories = async () => {
    try {
      const { productCategories, maxLength } = await requestGet({
        url: consts.apiBasicUrl + "/productcategories",
        query,
      });
      setMaxLength(maxLength);
      setData([...productCategories]);
    } catch (error) {
      dispatch(dialogOpenError(error));
    }
  };
  const handleSort = (name, { productCategoryId }) => async () => {
    try {
      await requestPatch({
        url: consts.apiBasicUrl + "/productcategories/" + productCategoryId,
        body: {
          action: name,
        },
      });
      fetchProductCategories();
    } catch (error) {
      dispatch(dialogOpenError(error));
    }
  };

  return (
    <div>
      <Table
        onFetch={fetchProductCategories}
        maxLength={maxLength}
        data={data}
        searchPlaceholder="검색"
        onRowClick={(item) => {
          history.push(routes.recommendationProductCategoryDetail, item);
        }}
        dateFilter={false}
        buttons={[
          {
            label: "등록",
            onClick: () => {
              history.push(routes.recommendationProductCategoryDetail);
            },
          },
        ]}
        title="상품 카테고리 관리"
        columns={[
          { label: "노출 순서", key: "sort", wrap: true },
          { label: "카테고리 명", key: "name", flex: 3 },
          {
            label: "등록상품 수",
            render: (_) => (
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "normal",
                  width: "100%",
                  height: "100%",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  history.push(
                    routes.recommendationProduct +
                      "?page=1&limit=10&productCategoryId=" +
                      _.productCategoryId
                  );
                }}
              >
                <span style={{ color: "blue", borderBottom: "1px solid blue" }}>
                  {_.length}
                </span>
              </span>
            ),
          },
          {
            label: "순서 변경",
            render: (_) => (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <Button
                  onClick={handleSort("up", _)}
                  color="primary"
                  style={{ marginRight: 16 }}
                  variant="outlined"
                >
                  올리기
                </Button>
                <Button
                  onClick={handleSort("down", _)}
                  color="secondary"
                  variant="outlined"
                >
                  내리기
                </Button>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default ProductCategory;
