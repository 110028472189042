import { userActionType } from "./UserActions";

const initData = {
  init: false,
  signed: false,
};

export default function user(state = initData, action) {
  switch (action.type) {
    case userActionType.init:
      return {
        ...state,
        init: true,
        ...action.admin,
        signed: action.signed,
      };
    default:
      return state;
  }
}
