import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TablePagination } from "@material-ui/core";
import queryString from "query-string";
const TablePaging = ({ location, history, maxLength }) => {
  const classes = useStyles();
  const query = queryString.parse(location.search);
  const { page, limit } = query;

  useEffect(() => {
    if (!page && !limit) {
      history.replace({
        pathname: location.pathname,
        search: queryString.stringify({
          ...query,
          page: "1",
          limit: "10",
        }),
      });
    } else if (!page) {
      history.replace({
        pathname: location.pathname,
        search: queryString.stringify({
          ...query,
          page: "1",
        }),
      });
    } else if (!limit) {
      history.replace({
        pathname: location.pathname,
        search: queryString.stringify({
          ...query,
          limit: "10",
        }),
      });
    }
  }, [page, limit]);

  const handleChangePage = (event, newPage) => {
    history.replace({
      pathname: location.pathname,
      search: queryString.stringify({
        ...query,
        page: newPage + 1,
      }),
    });
  };

  const handleChangeRowsPerPage = (event) => {
    history.replace({
      pathname: location.pathname,
      search: queryString.stringify({
        ...query,
        page: "1",
        limit: parseInt(event.target.value, 10),
      }),
    });
  };

  if (parseInt(maxLength) >= 1) {
    return (
      <div className={classes.root}>
        <TablePagination
          component="div"
          count={maxLength || 0}
          page={parseInt(page) - 1}
          onChangePage={handleChangePage}
          rowsPerPage={parseInt(limit)}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage="노출개수"
        />
      </div>
    );
  }

  return null;
};

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "16px 0px",
  },
}));
export default TablePaging;
