import { dialogOpenMessage } from "../dialog/DialogActions";
import { requestGet } from "../../libs/network";
import api from "../../libs/api";
import consts from "../../libs/consts";

export const dataActionType = {
  fetch: "data/fetch",
};

export const dataFetch = async (dispatch) => {
  try {
    const { productCategories } = await requestGet({
      url: consts.apiBasicUrl + "/productcategories",
    });
    const categories = await requestGet({ url: api.getCategories });
    const permissions = await requestGet({
      url: consts.apiBasicUrl + "/admins/permissions",
    });
    const langs = [];
    const levels = [];
    categories.forEach((category) => {
      category.value = category.categoryId;
      switch (category.type) {
        case "hair-level":
          levels.push(category);
          break;
        case "lang-code":
          langs.push(category);
          break;
      }
    });
    dispatch({
      type: dataActionType.fetch,
      langs,
      levels,
      productCategories: productCategories.map((x) => ({
        ...x,
        label: x.name,
        value: x.productCategoryId,
      })),
      permissions: permissions,
      langCodeCategoryId: langs[0] ? langs[0].categoryId : null,
    });
  } catch (error) {
    console.log(error);
    dispatch(
      dialogOpenMessage({
        message: "인터넷 연결을 확인해주세요.",
      })
    );
  }
};
