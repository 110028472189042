import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import dialog from "./dialog/DialogReducer";
import data from "./data/DataReducer";
import user from "./user/UserReducer";
import { userActionType } from "./user/UserActions";

const appReducer = combineReducers({
  dialog: dialog,
  data: data,
  user: user,
});

const rootReducer = (state, action) => {
  if (action.type === userActionType.clear) {
    state = undefined;
  }
  return appReducer(state, action);
};

const store = createStore(rootReducer, {}, compose(applyMiddleware(thunk)));

export default store;
