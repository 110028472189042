import React, { useState, useEffect } from "react";
import Paging from "../../components/paging/Paging";
import { makeStyles, Button, Paper } from "@material-ui/core";
import InspectionHairItem from "./InspectionHairItem";
import queryString from "query-string";
import InspeactionHairDialog from "./InspectionHairDialog";
import TableDateButtons from "../../components/table/TableDateButtons";
import routes from "../../libs/routes";
import { useDispatch } from "react-redux";
import {
  dialogOpenError,
  dialogOpenMessage,
} from "../../redux/dialog/DialogActions";
import { requestGet } from "../../libs/network";
import api from "../../libs/api";

const SearchUser = React.memo(({ classes, history, query, onCancel }) => {
  return (
    <div className={classes.searchRoot}>
      <Button
        onClick={() => {
          if (query.userId) {
            onCancel();
          } else {
            history.push(routes.memberUser, {
              action: { type: "select", route: routes.inspectionHair, query },
            });
          }
        }}
        lower
        style={{ fontWeight: "bold", textTransform: "none" }}
        color={query.userId ? "primary" : "secondary"}
        variant="contained"
      >
        {query.userId
          ? `${query.userName}/${query.userEmail} 회원 검색 취소`
          : "회원 검색"}
      </Button>
    </div>
  );
});

export default function InspectionHair({ location, history }) {
  const classes = useStyle();
  const [data, setData] = useState([]);
  const [maxLength, setMaxLength] = useState(0);
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState(null);
  const query = queryString.parse(location.search);

  useEffect(() => {
    if (location.state?.action) {
      if (location.state?.user) {
        history.replace({
          pathname: location.pathname,
          search: queryString.stringify({
            ...location.state.action.query,
            userId: location.state.user.userId,
            userEmail: location.state.user.email,
            userName: location.state.user.name,
          }),
        });
      } else if (location.state?.profile) {
        history.replace({
          pathname: location.pathname,
          search: queryString.stringify({
            ...location.state.action.query,
          }),
        });
      }
    } else if (location.state?.profile && location.state?.user) {
      history.replace({
        pathname: location.pathname,
        search: queryString.stringify({
          page: "1",
          limit: "10",
          userId: location.state.user.userId,
          userEmail: location.state.user.userEmail,
          userName: location.state.user.userName,
        }),
      });
    }
  }, [history, location.pathname, location.state]);

  useEffect(() => {
    if (query.page && query.limit) {
      fetchHairInspections();
    }
  }, [
    query.page,
    query.userId,
    query.dateStart,
    query.dateEnd,
    query.limit,
    fetchHairInspections,
  ]);

  const fetchHairInspections = async () => {
    try {
      dispatch(
        dialogOpenMessage({
          message: "모발 검사조회는 현재 준비중인 기능입니다.",
        })
      );
      return;
      const { results, maxLength } = await requestGet({
        url: api.getHairInspections,
        query: {
          dateStart: query.dateStart,
          dateEnd: query.dateEnd,
          limit: query.limit,
          page: query.page,
          userId: query.userId,
        },
      });
      setMaxLength(maxLength);
      setData([...results]);
    } catch (error) {
      dispatch(dialogOpenError(error));
    }
  };

  const replace = (data, q) => {
    history.push({
      pathname: location.pathname,
      search: queryString.stringify(
        q
          ? q
          : {
              ...query,
              ...data,
            }
      ),
    });
  };
  if (location.state?.action || location.state?.user) {
    return null;
  }
  return (
    <Paper className={classes.root}>
      <span className={classes.title}>{"모발 검사내역"}</span>
      <TableDateButtons
        onClick={(d) => {
          if (d) {
            replace(d);
          } else {
            delete query.dateStart;
            delete query.dateEnd;

            replace(null, query);
          }
        }}
        dateStart={query.dateStart}
      />
      <SearchUser
        query={query}
        history={history}
        onCancel={() => {
          delete query.userId;
          delete query.userName;
          delete query.userEmail;
          delete query.profileId;
          delete query.profileName;
          delete query.profileEmail;
          replace(null, query);
        }}
        classes={classes}
      />
      <span className={classes.maxLength}>
        검색 건수 <span>{maxLength}</span>
      </span>
      <div className={classes.main}>
        {data.map((x, i) => {
          return (
            <InspectionHairItem
              {...x}
              onClick={() => {
                setSelectedItem(x);
              }}
              key={i}
            />
          );
        })}
      </div>
      <Paging maxLength={maxLength} rowPerPage={parseInt(query.limit)} />
      <InspeactionHairDialog
        onClose={() => {
          setSelectedItem(null);
        }}
        open={Boolean(selectedItem)}
        {...selectedItem}
      />
    </Paper>
  );
}

const useStyle = makeStyles({
  root: {
    padding: "16px",
    display: "flex",
    flex: 1,
    alignItems: "center",
    flexDirection: "column",
  },
  main: {
    margin: "50px 0px",
    display: "flex",
    alignSelf: "stretch",
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
  },
  searchRoot: {
    alignSelf: "flex-end",
    justifyContent: "flex-end",
    marginTop: "10px",
  },
  title: {
    fontSize: "20px",
    fontWeight: "bold",
    alignSelf: "flex-start",
  },
  maxLength: {
    alignSelf: "flex-start",
    "& span": {
      color: "#f50057",
      fontWeight: "bold",
      fontSize: "17px",
    },
  },
});
