import React, { useState } from "react";
import Table from "../../components/table/Table";
import routes from "../../libs/routes";
import { useDispatch } from "react-redux";
import { dialogOpenError } from "../../redux/dialog/DialogActions";
import { requestGet } from "../../libs/network";
import api from "../../libs/api";
import { getQueryObj } from "../../libs/utils";
const Notification = ({ history, location }) => {
  const [maxLength, setMaxLength] = useState(0);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const query = getQueryObj(location);

  const fetchNotifications = async () => {
    try {
      const { notifications, maxLength } = await requestGet({
        url: api.getNotification,
        query,
      });
      setMaxLength(maxLength);
      setData([...notifications]);
    } catch (error) {
      dispatch(dialogOpenError(error));
    }
  };

  return (
    <div>
      <Table
        onFetch={fetchNotifications}
        maxLength={maxLength}
        data={data}
        title="알림사항"
        searchPlaceholder="제목, 메시지, 등록일"
        onRowClick={(item) => {
          history.push(routes.boardNotificationDetail, item);
        }}
        buttons={[
          {
            label: "등록",
            onClick: () => {
              history.push(routes.boardNotificationDetail);
            },
          },
        ]}
        columns={[
          { label: "제목", left: true, key: "title", flex: 2 },
          { label: "메시지", key: "message", flex: 3, maxLength: 50 },
          {
            label: "발송시간(작성시간)",
            key: "createdAt",
            type: "datetime",
          },
        ]}
      />
    </div>
  );
};

export default Notification;
