import React from "react";
import { Paper, makeStyles, Button } from "@material-ui/core";
import { withRouter } from "react-router";
import { useDispatch } from "react-redux";
import { Alert } from "@material-ui/lab";

const DetailLayout = ({
  title,
  children,
  history,
  onConfirm,
  onDelete,
  deleteMessage,
  buttonConfirm = true,
  buttonCancel = true,
  buttonConfirmLabel = "추가",
  buttonCancelLabel = "목록으로",
}) => {
  const classes = useStyle();
  return (
    <Paper className={classes.root}>
      <div className={classes.header}>
        <span className={classes.title}>{title}</span>

        {onDelete && (
          <div className={classes.div}>
            <Button color="secondary" onClick={onDelete} variant="contained">
              삭제하기
            </Button>

            {deleteMessage && (
              <span className={classes.waning}>{deleteMessage}</span>
            )}
          </div>
        )}
      </div>
      {children}
      <div className={classes.buttons}>
        {buttonCancel && (
          <Button
            color="secondary"
            variant="contained"
            onClick={() => {
              history.goBack();
            }}
            className={classes.button}
          >
            {buttonCancelLabel}
          </Button>
        )}
        {buttonConfirm && (
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              if (onConfirm) {
                onConfirm();
              }
            }}
            className={classes.button + " " + classes.buttonRight}
          >
            {buttonConfirmLabel}
          </Button>
        )}
      </div>
    </Paper>
  );
};

const useStyle = makeStyles({
  title: {
    fontSize: "20px",
    fontWeight: "bold",
  },
  header: {
    marginBottom: "25px",
    display: "flex",
    alignSelf: "stretch",
    alignItems: "center",
    justifyContent: "space-between",
  },
  root: {
    display: "flex",
    flexDirection: "column",
    padding: "16px",
  },
  buttons: {
    marginTop: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  button: {
    padding: "10px 50px",
    fontWeight: "bold",
  },
  buttonRight: {
    marginLeft: "16px",
  },
  div: {
    display: "flex",
    alignItems: "flex-end",
    flexDirection: "column",
  },
  waning: {
    color: "red",
    fontSize: "13px",
  },
});
export default withRouter(DetailLayout);
