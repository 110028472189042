import React, { useEffect } from "react";
import {
  BrowserRouter,
  Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import routes from "./libs/routes";
import Notice from "./pages/notice/Notice";
import MainLayout from "./layouts/main/MainLayout";
import DialogMessage from "./redux-components/dialog-message/DialogMessage";
import NoticeDetail from "./pages/notice-detail/NoticeDetail";
import { useSelector, useDispatch } from "react-redux";
import { dataFetch } from "./redux/data/DataActions";
import Inquiry from "./pages/inquiry/Inquiry";
import InquiryDetail from "./pages/inquiry-detail/InquiryDetail";
import Notification from "./pages/notification/Notification";
import NotificationDetail from "./pages/notification-detail/NotificationDetail";
import inspectResultHabit from "./pages/recommendation-habit/RecommendationHabit";
import inspectResultHabitDetail from "./pages/recommendation-habit-detail/RecommendationHabitDetail";
import RecommendationProduct from "./pages/recommendation-product/RecommendationProduct";
import RecommendationProductDetail from "./pages/recommendation-product-detail/RecommendationProductDetail";
import MemberUser from "./pages/member-user/MemberUser";
import MemberDetail from "./pages/member-detail/MemberDetail";
import MemberDelete from "./pages/member-delete/MemberDelete";
import MemberAdmin from "./pages/member-admin/MemberAdmin";
import MemberAdminDetail from "./pages/member-admin-detail/MemberAdminDetail";
import { userInit } from "./redux/user/UserActions";
import SignIn from "./pages/signin/SignIn";
import InspectionHair from "./pages/inspection-hair/InspectionHair";
import HairQuestionnaire from "./pages/hair-questionnaire/HairQuestionnaire";
import HairQuestionnaireDetail from "./pages/hair-questionnaire-detail/HairQuestionnaireDetail";
import Qna from "./pages/qna/Qna";
import QnaDetail from "./pages/qna-detail/QnaDetail";
import ProductCategory from "./pages/product-category/ProductCategory";
import ProductCategoryDetail from "./pages/product-category-detail/ProductCategoryDetail";

const SelectList = ({}) => {
  return <span>메뉴를 선택해주세요.</span>;
};

function App() {
  const dispatch = useDispatch();
  const userReducer = useSelector((s) => s.user, []);
  const { fetched } = useSelector((s) => s.data, []);
  useEffect(() => {
    if (!userReducer.init) {
      dispatch(userInit);
      return;
    }
    if (!fetched) {
      dispatch(dataFetch);
    }
  }, [userReducer.init]);
  if (!fetched) {
    return <h1>데이터 초기화중입니다...</h1>;
  }
  if (!userReducer.init) {
    return <h1>토큰 확인중입니다...</h1>;
  }
  return (
    <BrowserRouter>
      {!userReducer.signed ? (
        <Switch>
          <Route path={routes.signIn} component={SignIn} />
          <Redirect to={routes.signIn} />
        </Switch>
      ) : (
        <MainLayout>
          <Switch>
            <Route
              path={routes.badRequest}
              component={() => <span>잘못된 요청입니다.</span>}
            />
            <Route path={routes.inspectionHair} component={InspectionHair} />
            <Route
              path={routes.memberAdminDetail}
              component={MemberAdminDetail}
            />
            <Route path={routes.memberDetail} component={MemberDetail} />
            <Route path={routes.memberAdmin} component={MemberAdmin} />
            <Route path={routes.memberDelete} component={MemberDelete} />
            <Route path={routes.memberUser} component={MemberUser} />
            <Route path={routes.boardNoticeDetail} component={NoticeDetail} />
            <Route path={routes.boardNotice} component={Notice} />
            <Route path={routes.boardInquiryDetail} component={InquiryDetail} />
            <Route path={routes.boardInquiry} component={Inquiry} />

            <Route
              path={routes.hairQuestionnaireDetail}
              component={HairQuestionnaireDetail}
            />

            <Route
              path={routes.hairQuestionnaire}
              component={HairQuestionnaire}
            />

            <Route
              path={routes.boardNotificationDetail}
              component={NotificationDetail}
            />
            <Route path={routes.boardNotification} component={Notification} />
            <Route
              path={routes.inspectResultHabitDetail}
              component={inspectResultHabitDetail}
            />
            <Route
              path={routes.inspectResultHabit}
              component={inspectResultHabit}
            />

            <Route
              path={routes.recommendationProductCategoryDetail}
              component={ProductCategoryDetail}
            />
            <Route
              path={routes.recommendationProductCategory}
              component={ProductCategory}
            />

            <Route
              path={routes.recommendationProductDetail}
              component={RecommendationProductDetail}
            />
            <Route
              path={routes.recommendationProduct}
              component={RecommendationProduct}
            />
            <Route path={routes.qnaDetail} component={QnaDetail} />
            <Route path={routes.qna} component={Qna} />
            <Route component={SelectList} path={routes.member} />
            <Route component={SelectList} path={routes.inspection} />
            <Route component={SelectList} path={routes.board} />
            <Route component={SelectList} path={routes.recommendation} />
            <Route component={SelectList} path={routes.inspectResult} />
            <Redirect to={routes.notFound} />
          </Switch>
        </MainLayout>
      )}
      <DialogMessage />
    </BrowserRouter>
  );
}

export default App;
