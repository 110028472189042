import React, { useState } from "react";
import { Card, makeStyles } from "@material-ui/core";
import { getFileUri } from "../../libs/utils";

const LevelMark = ({ classes, level }) => {
  return (
    <div className={classes.levelMarkWrap}>
      <span className={classes.levelMark}>{level}</span>
    </div>
  );
};

const InspectionHairItem = ({ onClick, hairs, levelLabel }) => {
  const classes = useStyle();
  const [height, setHeight] = useState(0);
  if (!hairs) {
    return <span>이미지가 없습니다.</span>;
  }
  return (
    <div
      style={{ height }}
      ref={(ref) => {
        if (ref && !height) {
          setHeight(ref.clientWidth);
        }
      }}
      className={classes.root}
    >
      <Card onClick={onClick} className={classes.card}>
        <img
          alt="hair"
          src={getFileUri(hairs[0].path)}
          className={classes.img}
        />

        <LevelMark classes={classes} level={levelLabel} />
      </Card>
    </div>
  );
};

const useStyle = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "50%",
    },
    [theme.breakpoints.up("md")]: {
      width: "33.3%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "25%",
    },
    [theme.breakpoints.up("xl")]: {
      width: "20%",
    },
    width: "50%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    padding: "10px",
  },
  card: {
    flex: 1,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    cursor: "pointer",
    "&:hover": {
      boxShadow: "-1px 5px 14px 0px rgba(0,0,0,0.3)",
    },
  },
  img: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  levelMarkWrap: {
    position: "absolute",
    backgroundColor: "#fff",
    padding: "10px",
    top: 0,
    right: 0,
  },
  levelMark: {
    fontWeight: "bold",
  },
}));

export default InspectionHairItem;
