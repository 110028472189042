import React, { useEffect, useState, useRef } from "react";
import { makeStyles, Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import DetailLayout from "../../layouts/detail/DetailLayout";
import { dialogOpenError } from "../../redux/dialog/DialogActions";
import { requestGet } from "../../libs/network";
import api from "../../libs/api";
import Cells from "../../components/cells/Cells";
import routes from "../../libs/routes";

const MemberDetail = ({ history, location }) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [userId, setUserId] = useState(null);
  const [user, setUser] = useState({});
  const [action, setAction] = useState(false);

  useEffect(() => {
    if (location.state?.userId) {
      if (location.state?.action) {
        setAction({ ...location.state.action });
      }
      setUserId(location.state?.userId);
      requestGet({
        url: api.getMemberUser(location.state.userId),
      })
        .then((x) => {
          setUser(x);
          setUserId(x.userId);
        })
        .catch((error) => {
          dispatch(dialogOpenError(error));
          history.goBack();
        });
    }
  }, []);

  return (
    <DetailLayout
      buttonCancel={!Boolean(action)}
      onDelete={false}
      buttonConfirm={false}
      title={"회원 상세"}
    >
      <Cells
        label="회원 정보"
        data={[
          [
            { label: "이름", value: user.name },
            { label: "이메일", value: user.email },
          ],
          [
            { label: "생일", value: user.birthday, type: "date" },
            { label: "성별", value: user.genderLabel },
          ],
          [
            { label: "가입유형", value: user.methodLabel },
            { label: "가입일", value: user.createdAt, type: "datetime" },
          ],
          [
            {
              label: "회원번호",
              value: user.userId + "-" + user.memberId,
            },
            {
              label: "마지막 접속일",
              value: user.lastSignedAt,
              type: "datetime",
            },
          ],

          [
            {
              label: "국적",
              value: user.countryLabel,
            },
            {
              label: "인종",
              value: user.raceLabel,
            },
          ],
        ]}
      />
    </DetailLayout>
  );
};

const useStyle = makeStyles({
  profileCell: { marginTop: "16px" },
  profileCellFirst: {
    marginTop: "100px",
  },
  noProfile: {
    marginTop: "100px",
    fontWeight: "bold",
  },
});
export default MemberDetail;
