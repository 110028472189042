import moment from "moment-timezone";
import queryString from "query-string";
import consts from "./consts";
import { requestGet } from "./network";
export const setItem = (k, v) => window.localStorage.setItem(k, v);
export const getItem = (k) => window.localStorage.getItem(k);
function b64toBlob(dataURI, type) {
  var byteString = atob(dataURI.split(",")[1]);
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);

  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type });
}

export const fetchQuestionnaires = async () => {
  const { questionnaires, maxLength } = await requestGet({
    url: consts.apiBasicUrl + "/questionnaires",
  });

  return { questionnaires, maxLength };
};
export const getFileUri = (path) =>
  path ? consts.fileBasicUrl + "/" + path : "";

export const setFormFiles = (form, key, files) => {
  files.forEach((file) => {
    //const image = new File([u8arr], file.name, { type: file.type });
    form.append(key, b64toBlob(file.uri, file.type), file.name);
  });
};

export const setFormArray = (form, jsonArr, field) => {
  jsonArr.forEach((element, i) => {
    Object.entries(element).forEach(([key, value]) => {
      form.append(
        `${field}[${i}][${key}]`,
        typeof value === "string" ? value : String(value)
      );
    });
  });
};

export const getLangLabel = (code) => {
  switch (code) {
    case "ko-kr":
      return "한국어";
    case "ja-jp":
      return "일본어";
  }
};

export const getQueryObj = (location) => {
  if (!location || !location.search) {
    return {};
  }

  return queryString.parse(location.search);
};
export const initDefaultLangData = (fields) => {
  if (!fields) {
    return [];
  }
  const dataObj = {};
  fields.map((x) => {
    dataObj[x] = "";
  });

  return [
    { ...dataObj, code: "ko-kr" },
    { ...dataObj, code: "ja-jp" },
  ];
};

export const getStreamUsed = (x) => {
  return x.startTime !== null ? (x.isUsed === 0 ? "green" : "gray") : "";
};

export const getUseStateLabel = (useState) => {
  switch (useState) {
    case "all":
      return "전체";
    case "member":
      return "회원";
    case "subscriber":
      return "유료 회원";
    case "non-member":
      return "비회원";
  }
};

export const getKoTime = (date) => {
  if (date) {
    return moment(date);
  }
};

export const getToday = () => {
  return moment().tz("Asia/Seoul").format("YYYY-MM-DD");
};

export const getMomentBeforeDay = (v, unit) => {
  return moment().tz("Asia/Seoul").subtract(v, unit).format("YYYY-MM-DD");
};

export const formatTime = (time, format) => {
  let m;
  try {
    if (!time) {
      throw "";
    }
    m = moment(new Date(time));
  } catch (error) {
    return "";
  }
  return m.tz("Asia/Seoul").format(format);
};

export const getGender = (gender) => {
  return gender === "male" ? "남자" : gender === "female" ? "여자" : "무";
};

export const formatNum = (num) => {
  var regexp = /\B(?=(\d{3})+(?!\d))/g;
  return num !== undefined ? (num || "0").toString().replace(regexp, ",") : "";
};

export const phoneFomatter = (num, type) => {
  if (!num) {
    return "";
  }
  var formatNum = "";

  if (num.length == 11) {
    if (type == 0) {
      formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, "$1-****-$3");
    } else {
      formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
    }
  } else if (num.length == 8) {
    formatNum = num.replace(/(\d{4})(\d{4})/, "$1-$2");
  } else {
    if (num.indexOf("02") == 0) {
      if (type == 0) {
        formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, "$1-****-$3");
      } else {
        formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, "$1-$2-$3");
      }
    } else {
      if (type == 0) {
        formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, "$1-***-$3");
      } else {
        formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
      }
    }
  }

  return formatNum;
};

export const isImage = (filename) => {
  if (
    filename &&
    ["png", "jpeg", "jpg", "gif"].includes(filename.split(".").pop())
  ) {
    return true;
  }

  return false;
};
