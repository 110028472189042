import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Container from "@material-ui/core/Container";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ListItemText from "@material-ui/core/ListItemText";
import {
  People,
  BarChartOutlined,
  AddBox,
  InsertChart,
} from "@material-ui/icons";
import { withRouter } from "react-router";
import routes from "../../libs/routes";
import { useSelector, useDispatch } from "react-redux";
import { userSignOut } from "../../redux/user/UserActions";
import { dialogOpenMessage } from "../../redux/dialog/DialogActions";
import LogoImage from "../../assets/images/logo.png";
const drawerWidth = 240;

export default withRouter(function MainLayout({ children, history, location }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userReducer = useSelector((s) => s.user, []);
  const [open, setOpen] = React.useState(true);

  useEffect(() => {
    if (
      location.pathname === routes.main ||
      !location.pathname.replace("/", "").includes("/")
    ) {
      return;
    }

    const currentRouteValue = drawerList
      .map((x) => {
        const filters = x.items.filter((y) =>
          y.to.startsWith(location.pathname)
        );
        if (filters.length > 0) {
          return filters[0].value;
        } else {
          return false;
        }
      })
      .filter((x) => x)[0];
    if (currentRouteValue) {
      const values = userReducer.permission.split("&");
      if (!values.includes(currentRouteValue)) {
        dispatch(dialogOpenMessage({ message: "접근 권한이 없습니다." }));
        const to = drawerList
          .map((x) => {
            const filters = x.items.filter((y) => values.includes(y.value));
            if (filters.length > 0) {
              return filters[0].to;
            }
            return false;
          })
          .filter((x) => x)[0];
        if (to) {
          history.replace(to);
        } else {
          history.goBack();
        }
      }
    }
  }, [dispatch, history, location.pathname, userReducer.permission]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const signOut = () => {
    dispatch(userSignOut);
  };

  const handleTopCategoryPress = (x) => (e) => {
    history.push(x.to);
  };

  const handleNestedCategoryPress = (z) => (e) => {
    history.push(z.to);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            캔디고데기 관리자
          </Typography>
          <div className={classes.info}>
            <span className={classes.name}>
              <span>{userReducer.name}</span> 관리자님
            </span>

            <span onClick={signOut} className={classes.logout}>
              로그아웃
            </span>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <img alt="logo" src={LogoImage} className={classes.logo} />
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          {drawerList.map((x, i) => {
            let startWith = location.pathname.startsWith(x.to);
            const selected = startWith;

            return (
              <div
                className={classes.listItem}
                key={i.toString()}
                style={{
                  paddingBottom: selected ? "30px" : "0px",
                }}
              >
                <ListItem
                  className={classes.listRoot}
                  button
                  onClick={handleTopCategoryPress(x)}
                >
                  <ListItemIcon>{x.icon}</ListItemIcon>
                  <ListItemText
                    primary={x.label}
                    primaryTypographyProps={{
                      style: {
                        fontSize: selected ? "18px" : "16px",
                        fontWeight: "bold",
                      },
                    }}
                  />
                </ListItem>
                <List className={`${selected && classes.show}`}>
                  {x.items.map((z, y) => {
                    let nestedDisabled = false;
                    let bottomStartWith = location.pathname.startsWith(z.to);
                    const itemSelected = bottomStartWith && !nestedDisabled;
                    if (!userReducer.permission.includes(z.value)) {
                      nestedDisabled = true;
                    }
                    return (
                      <ListItem
                        disabled={nestedDisabled}
                        key={y.toString()}
                        button
                        onClick={handleNestedCategoryPress(z)}
                      >
                        <ListItemIcon>
                          {itemSelected && (
                            <div className={classes.itemSelected} />
                          )}
                        </ListItemIcon>

                        <ListItemText
                          primary={z.label}
                          primaryTypographyProps={{
                            style: {
                              fontSize: "16px",
                              fontWeight: "bold",
                            },
                          }}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </div>
            );
          })}
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          {children}
        </Container>
      </main>
    </div>
  );
});
const useStyles = makeStyles((theme) => ({
  logo: {
    width: "50%",
    objectFit: "contain",
  },
  info: {
    display: "flex",
    color: "#000",
    flexDirection: "row",
  },
  name: {
    marginRight: "16px",
    "& span": { fontWeight: "bold", color: "#000" },
  },
  logout: {
    color: "#FEBE10",
    fontWeight: "bold",
    cursor: "pointer",
  },
  itemSelected: {
    width: "10px",
    borderRadius: "100px",
    height: "10px",
    marginLeft: "8px",
    backgroundColor: "#fff",
  },
  listItem: {
    //  borderBottom: "1px solid rgba(0,0,0,0.12)",
    "& ul": {
      display: "none",
    },
    "&:hover": {
      "& ul": {
        display: "block !important",
      },
    },
  },
  show: {
    display: "block !important",
  },
  root: {
    display: "flex",
  },
  listRoot: {},
  toolbar: {
    paddingRight: 24,
    backgroundColor: "#fff",
    color: "#000",
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
    fontWeight: "bold",
    color: "#FEBE10",
  },
  drawerPaper: {
    backgroundColor: "#FEBE10",
    position: "relative",
    whiteSpace: "nowrap",
    color: "#fff",
    "& svg": {
      color: "#fff !important",
    },
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));
const drawerList = [
  {
    label: "회원 관리",
    icon: <People />,
    to: routes.member,
    items: [
      {
        to: routes.memberUser,
        label: "일반회원",
        value: "member-user",
      },
      //{
      //  to: routes.memberDelete,
      //  label: "탈퇴회원",
      //  value: "member-exit",
      //},
      {
        to: routes.memberAdmin,
        label: "관리자",
        value: "member-admin",
      },
    ],
  },
  /*
  {
    label: "검사 결과",
    icon: <BarChartOutlined />,
    //    value: "inspection/*",
    to: routes.inspection,
    items: [
      {
        to: routes.inspectionHair,
        label: "모발 검사",
        value: "inspection-hair",
      },
    ],
  },*/
  {
    label: "게시판 관리",
    icon: <DashboardIcon />,
    to: routes.board,
    //   value: "board/*",
    items: [
      {
        to: routes.boardNotice,
        label: "공지사항",
        value: "notice",
      },

      {
        to: routes.boardNotification,
        label: "알림사항",
        value: "notification",
      },
      {
        to: routes.boardInquiry,
        label: "문의사항",
        value: "inquiry",
      },
      {
        to: routes.qna,
        label: "모발상식 Q&A",
        value: "qna",
      },
    ],
  },

  {
    label: "상품 관리",
    icon: <AddBox />,
    to: routes.recommendation,
    items: [
      {
        to: routes.recommendationProduct,
        label: "제휴 상품 관리",
        value: "product",
      },
      {
        value: "product-category",
        label: "상품 카테고리 관리",
        to: routes.recommendationProductCategory,
      },
    ],
  },
  {
    label: "모발 검사 관리",
    icon: <InsertChart />,
    to: routes.inspectResult,
    items: [
      {
        to: routes.hairQuestionnaire,
        label: "설문지 및 코멘트",
        value: "hair-questionnaire",
      },
      {
        to: routes.inspectResultHabit,
        label: "검사단계별 코멘트",
        value: "recommandation",
      },
    ],
  },
  /*
  {
    label: "데이터 관리",
    icon: <DataUsage />,
    to: routes.data,
    items: [
      {
        to: routes.dataPolicy,
        label: "약관",
      },
    ],
  },*/
];
