import React, { useEffect, useState, useRef } from "react";
import MultiLangForm from "../../components/multi-lang-form/MultiLangForm";
import { makeStyles } from "@material-ui/core";
import FileSelector from "../../components/file-selector/FileSelector";
import { useDispatch } from "react-redux";
import DetailLayout from "../../layouts/detail/DetailLayout";
import { dialogOpenError } from "../../redux/dialog/DialogActions";
import {
  requestPost,
  requestFile,
  requestGet,
  requestDelete,
} from "../../libs/network";
import api from "../../libs/api";
import { setFormArray, setFormFiles } from "../../libs/utils";

const NoticeDetail = ({ history, location }) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const langFormRef = useRef();
  const [noticeId, setNoticeId] = useState(null);
  const [files, setFiles] = useState([]);
  const [langData, setLangData] = useState();

  useEffect(() => {
    if (location.state?.noticeId) {
      setNoticeId(location.state?.noticeId);
      requestGet({ url: api.getNotice(location.state.noticeId) })
        .then((x) => {
          setNoticeId(x.noticeId);
          setFiles([...x.images]);
          setLangData([...x.translations]);
        })
        .catch((error) => {
          dispatch(dialogOpenError(error));
          history.goBack();
        });
    }
  }, []);

  const handleConfirm = async () => {
    if (langFormRef.current.check()) {
      try {
        const form = new FormData();
        setFormArray(form, langData, "translations");
        setFormFiles(
          form,
          "images",
          files.filter((x) => x.uri)
        );
        if (files.filter((x) => x.isDeleted).length > 0) {
          setFormArray(
            form,
            files.filter((x) => x.isDeleted),
            "noticeImages"
          );
        }
        await requestFile({
          url: noticeId ? api.putNotice(noticeId) : api.insertNotice,
          form,
          method: noticeId ? "put" : "post",
        });
        history.goBack();
      } catch (error) {
        dispatch(dialogOpenError(error));
      }
    }
  };

  const deleteNotice = async () => {
    try {
      await requestDelete({ url: api.deleteNotice(noticeId) });
      history.goBack();
    } catch (error) {
      dispatch(dialogOpenError(error));
    }
  };

  return (
    <DetailLayout
      onDelete={noticeId && deleteNotice}
      buttonConfirmLabel={noticeId ? "수정" : "등록"}
      title={noticeId ? "공지사항 수정" : "공지사항 등록"}
      onConfirm={handleConfirm}
    >
      <FileSelector
        maxLength={5}
        files={files.filter((x) => !x.isDeleted)}
        acceptImage
        label="이미지"
        onDelete={(file, index) => {
          if (file.noticeImageId) {
            setFiles([
              ...files.map((x, i) => ({
                ...x,
                isDeleted:
                  x.noticeImageId === file.noticeImageId ? 1 : x.isDeleted,
              })),
            ]);
          } else {
            setFiles([...files.filter((x, i) => i !== index)]);
          }
        }}
        onAdd={(file) => {
          setFiles([...files, file]);
        }}
      />

      <MultiLangForm
        onDataChange={setLangData}
        ref={langFormRef}
        forms={[
          {
            type: "input",
            label: "제목",
            key: "title",
            maxLength: 1000,
            required: true,
          },
          {
            type: "multi",
            label: "내용",
            key: "description",
            maxLength: 2000,
            required: true,
          },
        ]}
        data={langData}
      />
    </DetailLayout>
  );
};

const useStyle = makeStyles({});
export default NoticeDetail;
