import { dialogActionType } from "./DialogActions";

const initDialog = {
  message: {
    open: false,
    onClick: null,
    close: null,
    message: null,
    title: null,
  },
};

export default function dialog(state = initDialog, action) {
  switch (action.type) {
    case dialogActionType.openMesasge:
      return {
        ...state,
        message: {
          open: true,
          onClick: action.onClick,
          close: action.close,
          message: action.message,
        },
      };
    case dialogActionType.close:
      return {
        ...initDialog,
      };
    default:
      return state;
  }
}
