import React, { useState } from "react";
import Table from "../../components/table/Table";
import routes from "../../libs/routes";
import { useDispatch } from "react-redux";
import { dialogOpenError } from "../../redux/dialog/DialogActions";
import { requestGet } from "../../libs/network";
import api from "../../libs/api";
import { getQueryObj } from "../../libs/utils";
const Inquiry = ({ history, location }) => {
  const [maxLength, setMaxLength] = useState(0);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const query = getQueryObj(location);

  const fetchInquiries = async () => {
    try {
      const { inquiries, maxLength } = await requestGet({
        url: api.getInquiries,
        query,
      });
      setMaxLength(maxLength);
      setData([...inquiries]);
    } catch (error) {
      dispatch(dialogOpenError(error));
    }
  };

  return (
    <div>
      <Table
        onFetch={fetchInquiries}
        title="문의사항"
        maxLength={maxLength}
        data={data}
        searchPlaceholder="내용, 사용자(이메일, 이름)"
        onRowClick={(item) => {
          history.push(routes.boardInquiryDetail, item);
        }}
        filters={[
          {
            data: [
              { label: "개선사항", value: "improvements" },
              { label: "모발검사", value: "inspection" },
              { label: "제휴 문의", value: "partner" },
              { label: "계정 관리", value: "account" },
              { label: "사용후기", value: "review" },
              { label: "고객지원 서비스", value: "support" },
              { label: "기타", value: "others" },
            ],
            key: "inquirytype",
          },
        ]}
        columns={[
          {
            label: "작성자",
            key: "name",
          },
          {
            label: "문의 유형",
            render: (x) => {
              switch (x.type) {
                case "improvements":
                  return "개선사항";
                case "inspection":
                  return "모발검사";
                case "partner":
                  return "제휴 문의";
                case "account":
                  return "계정 관리";
                case "review":
                  return "사용후기";
                case "support":
                  return "고객지원 서비스";
                case "others":
                  return "기타";
                default:
                  return "";
              }
            },
          },
          {
            label: "문의 내용",
            left: true,
            key: "description",
            flex: 3,
            maxLength: 50,
          },
          {
            label: "작성일",
            key: "createdAt",
            type: "datetime",
          },
          {
            label: "답변여부",
            key: "reply",
            type: "boolean",
          },
        ]}
      />
    </div>
  );
};

export default Inquiry;
