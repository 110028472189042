import React, { useState, useEffect } from "react";
import Table from "../../components/table/Table";
import routes from "../../libs/routes";
import { useDispatch, useSelector } from "react-redux";
import { dialogOpenError } from "../../redux/dialog/DialogActions";
import { requestGet } from "../../libs/network";
import api from "../../libs/api";
import { getQueryObj } from "../../libs/utils";
import { makeStyles } from "@material-ui/core";
const RecommendationHabit = ({ history, location }) => {
  const classes = useStyle();
  const { levels } = useSelector((s) => s.data, []);
  const [maxLength, setMaxLength] = useState(0);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const query = getQueryObj(location);

  const fetchRecommendations = async () => {
    try {
      const { recommendations, maxLength } = await requestGet({
        url: api.getRecommendations,
        query,
      });
      setMaxLength(maxLength);
      setData([...recommendations]);
    } catch (error) {
      dispatch(dialogOpenError(error));
    }
  };

  return (
    <div>
      <Table
        onFetch={fetchRecommendations}
        maxLength={maxLength}
        data={data}
        searchPlaceholder="내용"
        onRowClick={(item) => {
          history.push(routes.inspectResultHabitDetail, item);
        }}
        buttons={[
          {
            label: "등록",
            onClick: () => {
              history.push(routes.inspectResultHabitDetail);
            },
          },
        ]}
        title="검사단계별 코멘트 관리"
        columns={[
          { label: "내용", left: true, key: "summary", flex: 3 },
          { label: "검사레벨", key: "hairLevelLabel" },
        ]}
        filters={[
          {
            key: "hairLevelCategoryId",
            data: levels,
          },
        ]}
      />
    </div>
  );
};

const useStyle = makeStyles({
  typeWrap: {
    display: "flex",
    alignItems: "center",
  },
});

export default RecommendationHabit;
