import React, { useState } from "react";
import Table from "../../components/table/Table";
import routes from "../../libs/routes";
import { useDispatch, useSelector } from "react-redux";
import { dialogOpenError } from "../../redux/dialog/DialogActions";
import { requestGet, requestPatch } from "../../libs/network";
import api from "../../libs/api";
import { getQueryObj, getFileUri } from "../../libs/utils";
import { Button, makeStyles } from "@material-ui/core";
import consts from "../../libs/consts";

const RenderImage = ({ classes, path }) => {
  const [height, setHeight] = useState(null);
  return (
    <div
      ref={(ref) => {
        if (ref && !height) {
          setHeight(ref.clientWidth);
        }
      }}
      className={classes.imgWrap}
      style={
        height
          ? {
              height,
            }
          : {}
      }
    >
      <img alt="path" className={classes.img} src={getFileUri(path)} />
    </div>
  );
};

export default function RecommendationProduct({ history, location }) {
  const classes = useStyle();
  const [maxLength, setMaxLength] = useState(0);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const query = getQueryObj(location);
  const dataReducer = useSelector((s) => s.data, []);
  const fetchProducts = async () => {
    try {
      const { products, maxLength } = await requestGet({
        url: api.getProducts,
        query,
      });
      setMaxLength(maxLength);
      setData([...products]);
    } catch (error) {
      dispatch(dialogOpenError(error));
    }
  };
  const handleSort = (name, { productId }) => async () => {
    try {
      await requestPatch({
        url: consts.apiBasicUrl + "/products/" + productId,
        body: {
          action: name,
        },
      });
      fetchProducts();
    } catch (error) {
      dispatch(dialogOpenError(error));
    }
  };

  return (
    <div>
      <Table
        effects={["productCategoryId"]}
        onFetch={fetchProducts}
        maxLength={maxLength}
        data={data}
        searchPlaceholder="상품명"
        onRowClick={(item) => {
          history.push(routes.recommendationProductDetail, item);
        }}
        buttons={[
          {
            label: "등록",
            onClick: () => {
              history.push(routes.recommendationProductDetail);
            },
          },
        ]}
        title="제휴상품 관리"
        columns={[
          { label: "노출 순서", key: "sort", wrap: true },
          {
            label: "상품 이미지",
            key: "path",
            render: (item) => <RenderImage {...item} classes={classes} />,
          },
          { label: "상품명", key: "name", flex: 3 },
          { label: "상품가격", key: "price", type: "price" },

          {
            label: "노출 순서 변경",
            render: (_) => (
              <div
                className={classes.buttons}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <Button
                  onClick={handleSort("up", _)}
                  color="primary"
                  style={{ marginBottom: 8 }}
                  variant="outlined"
                >
                  올리기
                </Button>
                <Button
                  onClick={handleSort("down", _)}
                  color="secondary"
                  variant="outlined"
                >
                  내리기
                </Button>
              </div>
            ),
          },
        ]}
        filters={[
          {
            data: dataReducer.productCategories,
            key: "productCategoryId",
          },
        ]}
      />
    </div>
  );
}

const useStyle = makeStyles({
  typeWrap: {
    display: "flex",
    alignItems: "center",
  },
  img: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  imgWrap: {
    border: "1px solid rgba(0,0,0,0.13)",
    width: "100%",
  },
  buttons: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
});
