import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useSelector } from "react-redux";

const MenuWrapBorder = ({
  className,
  value,
  onChange,
  data,
  label,
  placeholder,
}) => {
  const classes = useStyles();
  const [rd, setRd] = useState();

  useEffect(() => {
    if (data) {
      setRd([{ label: "선택 안함", value: "" }, ...data]);
    }
  }, [data]);
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  if (!rd) {
    return null;
  }

  return (
    <FormControl
      variant="outlined"
      className={classes.formControl + " " + className}
    >
      <h3>{label}</h3>
      <Select
        labelId="hair-level-label"
        id="hair-level"
        value={value}
        onChange={handleChange}
      >
        {rd &&
          rd.map((level, index) => {
            return (
              <MenuItem key={index.toString()} value={level.value}>
                {level.label}
              </MenuItem>
            );
          })}
      </Select>
      {placeholder && (
        <span className={classes.placeholder}>{placeholder}</span>
      )}
    </FormControl>
  );
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    alignSelf: "flex-start",
    minWidth: 120,
  },
  placeholder: {
    color: "gray",
    fontSize: "14px",
  },
}));

export default MenuWrapBorder;
