import React, { useState } from "react";
import Table from "../../components/table/Table";
import routes from "../../libs/routes";
import { useDispatch } from "react-redux";
import { dialogOpenError } from "../../redux/dialog/DialogActions";
import { requestGet } from "../../libs/network";
import api from "../../libs/api";
import { getQueryObj } from "../../libs/utils";

const MemberAdmin = ({ history, location }) => {
  const [maxLength, setMaxLength] = useState(0);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const query = getQueryObj(location);

  const fetchAdmins = async () => {
    try {
      const { admins, maxLength } = await requestGet({
        url: api.getMemberAdmins,
        query,
      });
      setMaxLength(maxLength);
      setData([...admins]);
    } catch (error) {
      dispatch(dialogOpenError(error));
    }
  };

  return (
    <div>
      <Table
        title="관리자회원 목록"
        onFetch={fetchAdmins}
        maxLength={maxLength}
        data={data}
        searchPlaceholder="이름, 아이디"
        onRowClick={(item) => {
          history.push(routes.memberAdminDetail, item);
        }}
        buttons={[
          {
            label: "등록",
            onClick: () => {
              history.push(routes.memberAdminDetail);
            },
          },
        ]}
        columns={[
          {
            label: "이름",
            key: "name",
          },
          {
            label: "아이디",
            key: "username",
          },
          {
            label: "등록일",
            key: "createdAt",
            type: "datetime",
          },
        ]}
      />
    </div>
  );
};

export default MemberAdmin;
