import React from "react";
import { makeStyles, Radio } from "@material-ui/core";
import { formatTime, formatNum } from "../../libs/utils";

const TableItem = ({
  columns,
  data,
  onClick,
  onRadioChange,
  onActionSize,
  no,
}) => {
  const classes = useStyle();
  let nowidth = document.getElementById(`no`).clientWidth || 0;
  return (
    <div
      className={classes.root}
      onClick={onClick}
      style={{ cursor: onClick ? "pointer" : "normal" }}
    >
      {
        <div
          className={classes.item}
          style={{ maxWidth: nowidth, width: nowidth }}
        >
          <span>{no}</span>
        </div>
      }
      {Boolean(onRadioChange) && (
        <div
          ref={(ref) => {
            if (ref) {
              onActionSize(ref.offsetWidth, ref.offsetHeight);
            }
          }}
          style={{ display: "flex", alignItems: "center" }}
          onClick={() => {
            onRadioChange(!data.radio);
          }}
        >
          <Radio checked={data.radio || false} />
        </div>
      )}
      {columns.map((x, i) => {
        let value = data[x.key];
        if (x.maxLength && value.length > x.maxLength) {
          value = value.substring(0, 50) + "...";
        }
        let width;
        if (x.wrap) {
          const el = document.getElementById(`${x.label}-${i}`);
          width = el.clientWidth;
        }
        return (
          <div
            className={classes.item}
            key={i.toString()}
            style={{
              ...(width
                ? { maxWidth: width, width }
                : {
                    flex: x.flex || 1,
                  }),
              alignItems: x.left ? "flex-start" : "center",
              padding: x.left ? `16px 30px` : `16px`,
            }}
          >
            {x.render ? (
              x.render(data)
            ) : (
              <span>
                {x.type === "datetime"
                  ? formatTime(value, "YYYY-MM-DD HH:mm")
                  : x.type === "date"
                  ? formatTime(value, "YYYY-MM-DD")
                  : x.type === "number"
                  ? formatNum(value)
                  : x.type === "boolean"
                  ? value === 1
                    ? "O"
                    : "X"
                  : x.type === "price"
                  ? formatNum(value) + "원"
                  : value}
              </span>
            )}
          </div>
        );
      })}
    </div>
  );
};

const useStyle = makeStyles({
  root: {
    borderBottom: "1px solid rgba(0,0,0,0.13)",
    display: "flex",
    flexDirection: "row",
    alignSelf: "stretch",
  },
  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "16px",
    justifyContent: "center",
  },
});

export default TableItem;
