import React, { useEffect, useState, useRef } from "react";
import MultiLangForm from "../../components/multi-lang-form/MultiLangForm";
import { FormControl, makeStyles, MenuItem, Select } from "@material-ui/core";
import FileSelector from "../../components/file-selector/FileSelector";
import { useDispatch, useSelector } from "react-redux";
import DetailLayout from "../../layouts/detail/DetailLayout";
import { dialogOpenError } from "../../redux/dialog/DialogActions";
import { requestDelete, requestPut, requestFile } from "../../libs/network";
import api from "../../libs/api";
import { setFormArray, setFormFiles } from "../../libs/utils";
import HairLevelSelects from "../../components/hair-level-selects/HairLevelSelects";

export default function RecommendationProductDetail({ history, location }) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const langFormRef = useRef();
  const [productId, setProductId] = useState(null);
  const [langData, setLangData] = useState();
  const [files, setFiles] = useState([]);
  const [productCategoryId, setProductCategoryId] = useState("");
  const dataReducer = useSelector((s) => s.data, []);
  const [hairLevelCategoryId, setHairLevelCategoryId] = useState("");
  const [deletedImages, setDeletedImages] = useState([]);

  useEffect(() => {
    if (location.state?.productId) {
      const {
        translations,
        productCategoryId,
        hairLevelCategoryId,
        images,
      } = location.state;
      setProductId(location.state?.productId);
      setProductCategoryId(productCategoryId.toString());
      setHairLevelCategoryId(hairLevelCategoryId);
      setLangData(translations.map((x) => ({ ...x, name: x.productName })));
      setFiles([...images]);
    }
  }, []);

  const handleConfirm = async () => {
    try {
      if (files.length < 1) {
        throw "상품 이미지를 등록해주세요.";
      } else if (!langFormRef.current.check()) {
        return;
      } else if (!productCategoryId) {
        throw "상품 카테고리를 선택해주세요.";
      }
      const form = new FormData();
      setFormArray(form, langData, "translations");
      const newImages = files.filter((x) => !x.productImageId);
      setFormFiles(form, "files", newImages);
      form.append("productCategoryId", productCategoryId);
      form.append("hairLevelCategoryId", hairLevelCategoryId || "");
      if (productId) {
        deletedImages.forEach((id) => {
          form.append("deleteProductImageIds", id);
        });
        await requestPut({
          url: api.putProduct(productId),
          body: form,
        });
      } else {
        await requestFile({
          url: api.insertProduct,
          form,
        });
      }

      history.goBack();
    } catch (error) {
      dispatch(dialogOpenError(error));
    }
  };

  const deleteData = async () => {
    try {
      await requestDelete({
        url: api.deleteProduct(productId),
      });
      history.goBack();
    } catch (error) {
      dispatch(dialogOpenError(error));
    }
  };

  return (
    <DetailLayout
      onDelete={productId && deleteData}
      buttonConfirmLabel={productId ? "수정" : "등록"}
      title={productId ? "제휴상품 수정" : "제휴상품 등록"}
      onConfirm={handleConfirm}
    >
      <FormControl className={classes.cateogry} variant="outlined">
        <h3>상품 카테고리 선택</h3>
        <Select
          value={productCategoryId}
          onChange={(e) => {
            setProductCategoryId(e.target.value);
          }}
        >
          {dataReducer.productCategories.map((level, index) => {
            return (
              <MenuItem key={index.toString()} value={level.value.toString()}>
                {level.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <HairLevelSelects
        className={classes.cateogry}
        value={hairLevelCategoryId}
        onChange={setHairLevelCategoryId}
      />
      <FileSelector
        acceptImage
        className={classes.form}
        label="상품 이미지"
        maxLength={10}
        files={files}
        onDelete={(data, i) => {
          if (data.productImageId) {
            setDeletedImages([...deletedImages, data.productImageId]);
          }
          setFiles([...files.filter((y, z) => z !== i)]);
        }}
        onAdd={(file) => {
          setFiles([...files, file]);
        }}
      />

      <MultiLangForm
        label="상품 정보 입력"
        onDataChange={setLangData}
        ref={langFormRef}
        forms={[
          {
            type: "input",
            label: "상품명",
            key: "name",
            maxLength: 100,
            required: true,
          },
          {
            label: "가격",
            key: "price",
            type: "number",
            required: true,
          },
          {
            label: "구매 URL",
            key: "url",
            type: "url",
            maxLength: 1000,
            required: true,
          },

          {
            label: "상품 설명",
            key: "description",
            type: "multi",
            maxLength: 2000,
            required: true,
          },
        ]}
        data={langData}
      />
    </DetailLayout>
  );
}

const useStyle = makeStyles({
  form: {
    marginBottom: "30px",
  },
  cateogry: {
    alignSelf: "flex-start",
    marginBottom: "30px",
  },
});
