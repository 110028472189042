const devMode = false;
export default {
  devMode,
  apiBasicUrl: devMode ? "http://localhost:8080" : "https://api.candy-iron.com",
  fileBasicUrl: devMode
    ? "https://file.candy-iron.com"
    : "https://file.candy-iron.com",
  imageTypes: [".png", ".jpg", ".jpeg", "image/png", "image/jpg", "image/jpeg"],
  docTypes: [".pdf", ".doc", ".docx", ".ppt", ".pptx", ".xlsx"],
  loginTokenKey: "candy-iron-token",
  langCodeCategoryIdKorea: 1,
};
