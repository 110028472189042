import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  Tabs,
  Tab,
  makeStyles,
  TextField,
  Button,
  Grid,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  dialogOpenMessage,
  dialogOpenLangAlert,
  dialogOpenError,
} from "../../redux/dialog/DialogActions";
import { requestPost } from "../../libs/network";
import consts from "../../libs/consts";

//forms : [{label, key ,type}]
//data : [{code,...}]
const MultiLangForm = (
  {
    readOnly,
    forms,
    data,
    button,
    className,
    onDataChange,
    label,
    options,
    onFormDelete,
    answer,
  },
  ref
) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [langIndex, setLangIndex] = useState(0);
  const [tempData, setTempData] = useState([]);
  const dataReducer = useSelector((s) => s.data, []);
  const [changed, setChanged] = useState(false);

  useImperativeHandle(ref, () => ({
    isChanged: () => {
      if (changed) {
        return true;
      } else {
        return false;
      }
    },
    check: () => {
      let message = "다국어 폼에 미입력된 항목이 있습니다.";
      if (
        tempData.filter((x) => {
          let needInput = false;
          Object.keys(x).map((key) => {
            /*
            if (
              key !== "code" &&
              !x[key] &&
              forms[forms.findIndex((y) => y.key === key)].required
            ) {
              //  needInput = true;
            }*/
          });
          return needInput;
        }).length > 0
      ) {
        dispatch(
          dialogOpenMessage({
            message: message,
          })
        );
        return false;
      }

      if (changed) {
        dispatch(dialogOpenLangAlert);
        return false;
      }
      return true;
    },
  }));

  useEffect(() => {
    if (data) {
      setChanged(false);
      setTempData([...data]);
    } else {
      const dataKeys = [];
      forms.forEach((obj) => {
        dataKeys.push(obj.key);
      });
      const newData = [];
      dataReducer.langs.forEach((lang) => {
        const object = {
          langCodeCategoryId: lang.categoryId,
        };

        dataKeys.forEach((key, i) => {
          object[key] = "";
        });
        newData.push(object);
      });
      onDataChange(newData);
    }
  }, [data]);

  const handleValueChange = (form, data, value, isSummary) => {
    if (!changed) {
      setChanged(true);
    }

    let maxLength = form.maxLength;

    if (readOnly || (maxLength && value.length > maxLength)) {
      return;
    } else if (
      (form.type === "number" && value.length > 11) ||
      (form.type === "number" && isNaN(value) && value)
    ) {
      return;
    } else if (
      form.type === "url" &&
      value &&
      !value.startsWith("h") &&
      value.length === 1
    ) {
      setTempData([
        ...tempData.map((z) => {
          if (data.langCodeCategoryId === z.langCodeCategoryId) {
            z[form.key] = "";
          }
          return z;
        }),
      ]);
      alert("http://, https://로 시작하는 url을 입력해주세요.");
      return;
    }

    setTempData([
      ...tempData.map((z) => {
        if (data.langCodeCategoryId === z.langCodeCategoryId) {
          if (isSummary) {
            z["summary-" + form.key] = value;
          } else {
            z[form.key] = value;
          }
        } else {
          if (isSummary) {
            if (z["summary-" + form.key] === undefined) {
              z["summary-" + form.key] = "";
            }
          } else {
            if (z[form.key] === undefined) {
              z[form.key] = "";
            }
          }
        }
        return z;
      }),
    ]);
  };

  // const handleTranslaion = async () => {
  //   try{
  //     const labels = langData;
  //     const answers = answerLangData.map((x) => {
  //       const keys = Object.keys(x).filter((x) => x !== "langCodeCategoryId");
  //       const labels = keys.map((key) => x[key]);
  //       return {
  //         langCodeCategoryId: x.langCodeCategoryId,
  //         labels,
  //       };
  //     });
  //     const answerComments = answerForms.map((_) => {
  //       return _.comments.map((x) => {
  //         const keys = Object.keys(x).filter(
  //           (x) => x !== "langCodeCategoryId"
  //         );
  //         const labels = keys.map((key) => x[key]);
  //         return {
  //           langCodeCategoryId: x.langCodeCategoryId,
  //           labels,
  //         };
  //       });
  //     });
  // const translateData = await requestPost({
  //   url: consts.apiBasicUrl + "/translation",
  //   body: {
  //     labels,
  //     answers,
  //     answerComments,
  //   },
  // })
  //     console.log("translateData")
  //     console.log(translateData)
  //     setLangData(translateData.labels)
  //   }catch(error){
  //     dispatch(dialogOpenError(error));
  //   }
  // }

  const handleTranslate = async (form, value) => {
    const koreaTemp = tempData.filter(
      (x) => parseInt(x.langCodeCategoryId) === consts.langCodeCategoryIdKorea
    )[0];
    if (!koreaTemp || !koreaTemp[form.key]) {
      dispatch(
        dialogOpenError({
          message: "한국어를 먼저 입력해주세요.\n한국어에 맞추어 번역됩니다.",
        })
      );
    }
    const { translateData } = await requestPost({
      url: consts.apiBasicUrl + "/translation",
      body: {
        label: koreaTemp[form.key],
        langCodeCategoryId: value.langCodeCategoryId,
      },
    });
    if (form.type === "input-summary" && koreaTemp["summary-" + form.key]) {
      const translateData2 = (
        await requestPost({
          url: consts.apiBasicUrl + "/translation",
          body: {
            label: koreaTemp["summary-" + form.key],
            langCodeCategoryId: value.langCodeCategoryId,
          },
        })
      ).translateData;
      handleValueChange(form, value, translateData2, true);
    }
    handleValueChange(form, value, translateData);
  };

  return (
    <div className={classes.root + " " + className}>
      <div className={classes.titleBar}>
        <h3>{label !== undefined ? label : "내용 입력"}</h3>
        {options}
      </div>
      <Tabs
        className={classes.tabs}
        indicatorColor="primary"
        onChange={(e, v) => {
          setLangIndex(v);
        }}
        value={langIndex}
      >
        {dataReducer.langs.map((x, i) => {
          return (
            <Tab className={classes.tab} label={x.label} key={i.toString()} />
          );
        })}
      </Tabs>
      {
        //contents
      }
      <div className={classes.content}>
        {
          //forms
        }
        {tempData &&
          tempData.length > 0 &&
          forms &&
          forms.map((x, i) => {
            const value =
              tempData[
                tempData.findIndex(
                  (x) =>
                    x.langCodeCategoryId ===
                    dataReducer.langs[langIndex].categoryId
                )
              ] || {};
            return (
              <div key={i.toString() + langIndex} className={classes.form}>
                <div className={classes.formHedaer}>
                  {x.label && (
                    <span className={classes.formLabel}>
                      {x.label}
                      {x.required && (
                        <span className={classes.required}>*</span>
                      )}
                    </span>
                  )}
                  {x.maxLength && (
                    <span className={classes.formLabel}>
                      {value[x.key] ? value[x.key].length : "0"}/{x.maxLength}
                    </span>
                  )}
                </div>
                <div className={classes.formBody}>
                  {x.type === "multi" ? (
                    <TextField
                      fullWidth={x.wrap ? false : true}
                      multiline
                      className={classes.textFieldMulti}
                      variant="outlined"
                      value={value[x.key]}
                      onChange={(e) => {
                        handleValueChange(x, value, e.target.value);
                      }}
                    />
                  ) : x.type === "input-summary" ? (
                    <div className={classes.summaryWrap}>
                      <div className={classes.summaryItem}>
                        <span>요약</span>
                        <input
                          maxLength={100}
                          type="text"
                          className={classes.summaryInput}
                          placeholder="100자 이내로 작성해주세요."
                          value={value["summary-" + x.key]}
                          onChange={(e) => {
                            handleValueChange(x, value, e.target.value, true);
                          }}
                        />
                      </div>
                      <TextField
                        fullWidth={x.wrap ? false : true}
                        variant="outlined"
                        value={value[x.key]}
                        onChange={(e) => {
                          handleValueChange(x, value, e.target.value);
                        }}
                      />
                    </div>
                  ) : (
                    <TextField
                      fullWidth={x.wrap ? false : true}
                      variant="outlined"
                      value={value[x.key]}
                      onChange={(e) => {
                        handleValueChange(x, value, e.target.value);
                      }}
                    />
                  )}
                  {parseInt(value.langCodeCategoryId) !==
                    consts.langCodeCategoryIdKorea && (
                    <Button
                      className={classes.formTranslation}
                      variant="contained"
                      color="primary"
                      onClick={() => handleTranslate(x, value)}
                    >
                      언어 번역
                    </Button>
                  )}
                  {Boolean(button) && (
                    <Button
                      className={classes.formDelete}
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        button.onClick(x);
                      }}
                    >
                      {button.label}
                    </Button>
                  )}

                  {Boolean(onFormDelete) && (
                    <Button
                      className={classes.formDelete}
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        onFormDelete(x);
                      }}
                    >
                      삭제
                    </Button>
                  )}
                </div>
              </div>
            );
          })}
        {
          //buttons
        }
        <div className={classes.buttons}>
          <Button
            disabled={!changed || readOnly}
            color="primary"
            variant="outlined"
            className={classes.save}
            onClick={
              onDataChange &&
              (() => {
                onDataChange([...tempData]);
                setChanged(false);
              })
            }
          >
            저장
          </Button>
        </div>
      </div>
    </div>
  );
};

const useStyle = makeStyles({
  formHedaer: {
    marginBottom: "3px",
    display: "flex",
    alignSelf: "stretch",
    alignItems: "center",
    justifyContent: "space-between",
  },
  textFieldMulti: {
    "& textarea": {
      minHeight: "200px",
    },
  },
  titleBar: {
    display: "flex",
    alignItems: "center",
  },
  required: {
    color: "#3f51b5;",
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "20px",
    textAlign: "center",
  },
  summaryItem: {
    display: "flex",
    alignItems: "center",
    marginBottom: "8px",
  },
  summaryWrap: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  root: {
    display: "flex",
    flexDirection: "column",
  },
  summaryInput: {
    marginLeft: "10px",
    padding: "12px 6px",
    border: "1px solid rgba(0,0,0,0.23)",
    borderRadius: "3px",
    flex: 1,
  },
  tabs: {
    borderBottom: "1px solid rgba(0,0,0,0.23)",
  },
  tab: {
    color: "#000",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    padding: "0px 16px 16px 16px",
  },
  form: {
    flexDirection: "column",
    display: "flex",
    marginTop: "16px",
  },
  formLabel: {
    fontSize: "12px",
    color: "gray",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  buttons: {
    display: "flex",
    alignSelf: "flex-end",
  },
  save: {
    marginTop: "16px",
    padding: "10px 50px",
  },
  formBody: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "stretch",
  },
  formDelete: {
    marginLeft: "16px",
  },
  formTranslation: {
    marginLeft: "16px",
    minWidth: "100px",
  },
});

export default forwardRef(MultiLangForm);
