import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useSelector } from "react-redux";

const HairLevelSelects = ({ className, value, onChange }) => {
  const classes = useStyles();

  const { levels } = useSelector((s) => s.data, []);

  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <FormControl
      variant="outlined"
      className={classes.formControl + " " + className}
    >
      <h3>모발검사 단계</h3>
      <Select
        labelId="hair-level-label"
        id="hair-level"
        value={value}
        onChange={handleChange}
      >
        {levels &&
          levels.map((level, index) => {
            return (
              <MenuItem key={index.toString()} value={level.value}>
                {level.label}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    alignSelf: "flex-start",
    minWidth: 120,
  },
}));

export default HairLevelSelects;
