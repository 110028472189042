import React, { useState } from "react";
import { makeStyles, Paper, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import Cells from "../../components/cells/Cells";
import { getFileUri } from "../../libs/utils";

const InspeactionHairDialog = ({
  open,
  onClose,
  country,
  countryCategoryId,
  countryLabel,
  createdAt,
  email,
  hairInspectionId,
  hairLevelCategoryId,
  hairs,
  level,
  levelLabel,
  name,
  profileBirthday,
  profileEmail,
  profileGender,
  profileGenderLabel,
  profileId,
  profileName,
  race,
  raceCategoryId,
  raceLabel,
  userId,
}) => {
  const classes = useStyle();
  const [imgSize, setImgSize] = useState(null);

  const handleMainRef = (ref) => {
    if (ref && !imgSize) {
      const s = Math.ceil(ref.clientWidth / 4) + "px";
      setImgSize({ width: s, height: s });
    }
  };

  if (!open) {
    return null;
  }
  return (
    <div onClick={onClose} className={classes.root}>
      <Paper
        innerRef={handleMainRef}
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={classes.main}
      >
        <div className={classes.header}>
          <IconButton onClick={onClose}>
            <Close color="primary" />
          </IconButton>
        </div>
        <div className={classes.content}>
          <div className={classes.info}>
            <h3>기본 정보</h3>
            <Cells
              data={[
                [
                  { label: "회원 이름", value: name },
                  { label: "회원 이메일", value: email },
                ],
                [
                  { label: "프로필 이름", value: profileName },
                  { label: "프로필 이메일", value: profileEmail },
                ],
                [
                  {
                    label: "프로필 생년월일",
                    value: profileBirthday,
                    type: "date",
                  },
                  { label: "프로필 성별", value: profileGenderLabel },
                ],
                [
                  { label: "프로필 국적", value: countryLabel },
                  { label: "프로필 인종", value: raceLabel },
                ],
                [
                  { label: "검사 일자", value: createdAt, type: "date" },
                  { label: "검사 결과", value: levelLabel },
                ],
              ]}
            />
          </div>
          <h3 className={classes.hairLabel}>검사에 사용된 모발</h3>
          {Boolean(imgSize) && (
            <div className={classes.pic}>
              {hairs.map((hair, index) => {
                return (
                  <img
                    key={index}
                    style={{
                      ...imgSize,
                      marginLeft: index > 0 ? "16px" : "0px",
                    }}
                    alt="hair"
                    src={getFileUri(hair.path)}
                  />
                );
              })}
            </div>
          )}
        </div>
      </Paper>
    </div>
  );
};

const useStyle = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    zIndex: 2000,
    position: "fixed",
    bottom: 0,
    right: 0,
    top: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,0.3)",
  },
  main: {
    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "90%",
    },
    [theme.breakpoints.up("md")]: {
      width: "80%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "50%",
    },
    [theme.breakpoints.up("xl")]: {
      width: "30%",
    },
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    borderBottom: "1px solid #3f51b5",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    padding: "16px",
  },
  pic: {
    display: "flex",
    flexDirection: "row",
    overflowX: "auto",
    alignSelf: "stretch",
    marginBottom: "30px",
  },
  info: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  hairLabel: {
    marginTop: "50px",
  },
}));

export default InspeactionHairDialog;
