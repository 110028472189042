import React, { useState, useEffect } from "react";
import Table from "../../components/table/Table";
import routes from "../../libs/routes";
import { useDispatch } from "react-redux";
import { dialogOpenError } from "../../redux/dialog/DialogActions";
import { requestGet } from "../../libs/network";
import api from "../../libs/api";
import { getQueryObj } from "../../libs/utils";
const MemberUser = ({ history, location }) => {
  const [maxLength, setMaxLength] = useState(0);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const query = getQueryObj(location);
  const [action, setAction] = useState(false);
  useEffect(() => {
    if (location.state?.action) {
      setAction({ ...location.state.action });
    }
  }, [location.state]);

  const fetchNormalUsers = async () => {
    try {
      const { users, maxLength } = await requestGet({
        url: api.getMemberUsers,
        query: {
          ...query,
          isDeleted: 0,
          type: "user",
        },
      });
      setMaxLength(maxLength);
      setData([...users]);
    } catch (error) {
      dispatch(dialogOpenError(error));
    }
  };

  return (
    <div>
      <Table
        title={action?.type === "select" ? "회원 선택" : "일반회원 목록"}
        onFetch={fetchNormalUsers}
        maxLength={maxLength}
        data={data}
        searchPlaceholder="이름, 이메일"
        onRowClick={(item) => {
          if (action.type === "select") {
            history.replace(action.route, { user: item, action });
          } else {
            history.push(routes.memberDetail, item);
          }
        }}
        columns={[
          {
            label: "이름",
            key: "name",
          },
          {
            label: "이메일",
            key: "email",
          },
          {
            label: "가입유형",
            key: "methodLabel",
          },
          {
            label: "가입일",
            key: "createdAt",
            type: "datetime",
          },
          { label: "마지막 접속일", key: "lastSignedAt", type: "datetime" },
        ]}
        filters={[
          {
            key: "method",
            data: [
              { label: "자체회원", value: "email" },
              { label: "구글", value: "google" },
              { label: "애플", value: "apple" },
              { label: "페이스북", value: "facebook" },
            ],
          },
        ]}
      />
    </div>
  );
};

export default MemberUser;
