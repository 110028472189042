import React from "react";
import { makeStyles, Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import DetailLayout from "../../layouts/detail/DetailLayout";
import routes from "../../libs/routes";
import { Redirect } from "react-router-dom";
import Cells from "../../components/cells/Cells";
import EmailForm from "../../components/email-form/EmailForm";
import {
  dialogOpenError,
  dialogOpenMessage,
} from "../../redux/dialog/DialogActions";
import { requestDelete, requestPost } from "../../libs/network";
import api from "../../libs/api";
import consts from "../../libs/consts";

const InquiryDetail = ({ history, location }) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const {
    replyDescription,
    replyEmail,
    description,
    userId,
    name,
    email,
    inquiryId,
    type,
  } = location.state || {};

  const handleReply = async (description) => {
    try {
      await requestPost({
        url: api.replyInquiry(inquiryId),
        body: {
          description,
        },
      });
      history.goBack();

      dispatch(
        dialogOpenMessage({
          message: "메일 전송되었습니다.",
        })
      );
    } catch (error) {
      dispatch(dialogOpenError(error));
    }
  };

  if (!inquiryId) {
    return <Redirect to={routes.badRequest} />;
  }

  return (
    <DetailLayout
      onDelete={() => {
        requestDelete({ url: consts.apiBasicUrl + "/inquiries/" + inquiryId })
          .then(() => {
            history.goBack();
            dispatch(
              dialogOpenMessage({
                message: "삭제 되었습니다.",
                onClick: () => {},
              })
            );
          })
          .catch((e) => {
            dispatch(dialogOpenError(e));
          });
      }}
      buttonConfirm={false}
      title={"문의사항 상세"}
    >
      <div className={classes.writerWrap}></div>
      <Cells
        className={classes.cells}
        label="작성자"
        data={[
          [
            {
              label: "이름",
              value: (
                <span
                  className={classes.a}
                  onClick={() => {
                    history.push(routes.memberDetail, {
                      userId,
                    });
                  }}
                >
                  {name}
                </span>
              ),
            },
            {
              label: "이메일",
              value: (
                <span
                  className={classes.a}
                  onClick={() => {
                    history.push(routes.memberDetail, {
                      userId,
                    });
                  }}
                >
                  {email}
                </span>
              ),
            },
          ],
        ]}
      />
      <Cells
        className={classes.cells2}
        data={[
          [
            {
              label: "문의 유형",
              value: (() => {
                switch (type) {
                  case "improvements":
                    return "개선사항";
                  case "inspection":
                    return "모발검사";
                  case "partner":
                    return "제휴 문의";
                  case "account":
                    return "계정 관리";
                  case "review":
                    return "사용후기";
                  case "support":
                    return "고객지원 서비스";
                  case "others":
                    return "기타";
                  default:
                    return "";
                }
              })(),
            },
          ],
        ]}
      />

      <Cells
        className={classes.cells2}
        data={[
          [
            {
              label: "문의 내용",
              value: description,
            },
          ],
        ]}
      />

      <h3 className={classes.emailForm}>문의 답변</h3>
      <EmailForm
        onClick={handleReply}
        value={replyDescription}
        emails={replyEmail}
      />
    </DetailLayout>
  );
};

const useStyle = makeStyles({
  writerWrap: {
    display: "flex",
    alignItems: "center",
    "& button": {
      marginLeft: "16px",
    },
  },
  cells: {
    marginBottom: "15px",
  },
  cells2: {
    marginTop: "5px",
    "&:first-child": {
      marginTop: "20px",
      width: "50%",
      marginRight: "50%",
    },
  },
  emailForm: {
    marginTop: "50px",
  },
  a: {
    borderBottom: "1px solid black",
    cursor: "pointer",
  },
});
export default InquiryDetail;
