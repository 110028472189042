import React, { useEffect, useState, useRef } from "react";
import MultiLangForm from "../../components/multi-lang-form/MultiLangForm";
import { makeStyles } from "@material-ui/core";
import { useDispatch } from "react-redux";
import DetailLayout from "../../layouts/detail/DetailLayout";
import { dialogOpenError } from "../../redux/dialog/DialogActions";
import { requestPost, requestDelete, requestPut } from "../../libs/network";
import api from "../../libs/api";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import HairLevelSelects from "../../components/hair-level-selects/HairLevelSelects";
import { fetchQuestionnaires } from "../../libs/utils";
import MenuWrapBorder from "../../components/menu-wrap-border/MenuWrapBorder";

export default function InspectResultHabitDetail({ history, location }) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const langFormRef = useRef();
  const [hairRecommendationId, setHairRecommendationId] = useState(null);
  const [langData, setLangData] = useState();
  const [hairLevelCategoryId, setHairLevelCategoryId] = useState("");
  /*
  const [type, setType] = useState("");
  const [questionnaires, setQuestionnaires] = useState([]);
  const [hairQuestionnaireId, setHairQuestionnaireId] = useState(null);
  const [hairQuestionnaireAnswer, setHairQuestionnaireAnswer] = useState("0");
   */

  useEffect(() => {
    if (location.state?.hairRecommendationId) {
      const { translations, hairLevelCategoryId } = location.state;
      setHairRecommendationId(location.state?.hairRecommendationId);
      setLangData(translations);
      setHairLevelCategoryId(hairLevelCategoryId);
    }

    /**
     *  setHairQuestionnaireId(hairQuestionnaireId);
      setHairQuestionnaireAnswer(String(hairQuestionnaireAnswer));
      setType(type);
  fetchQuestionnaires()
      .then(({ questionnaires }) => {
        setQuestionnaires([
          ...questionnaires.map((x) => ({
            ...x,
            value: x.hairQuestionnaireId,
          })),
        ]);
      })
      .catch((e) => {
        dispatch(dialogOpenError(e));
      });
     */
  }, []);

  const handleConfirm = async () => {
    try {
      if (!hairLevelCategoryId) {
        throw "검사 단계를 선택해주세요.";
      } else if (!langFormRef.current.check()) {
        return;
      }

      if (hairRecommendationId) {
        await requestPut({
          url: api.putRecommendation(hairRecommendationId),
          body: {
            translations: langData,
            hairLevelCategoryId,
          },
        });
      } else {
        await requestPost({
          url: api.insertRecommendation,
          body: {
            translations: langData,
            hairLevelCategoryId,
          },
        });
      }

      history.goBack();
    } catch (error) {
      dispatch(dialogOpenError(error));
    }
  };

  const deleteData = async () => {
    try {
      await requestDelete({
        url: api.deleteRecommendation(hairRecommendationId),
      });
      history.goBack();
    } catch (error) {
      dispatch(dialogOpenError(error));
    }
  };

  return (
    <DetailLayout
      onDelete={hairRecommendationId && deleteData}
      buttonConfirmLabel={hairRecommendationId ? "수정" : "등록"}
      title={hairRecommendationId ? "추천습관 수정" : "추천습관 등록"}
      onConfirm={handleConfirm}
    >
      <HairLevelSelects
        className={classes.form}
        value={hairLevelCategoryId}
        onChange={setHairLevelCategoryId}
      />

      {/*
      <RecommendationTypes value={type} onChange={setType} classes={classes} />
  <MenuWrapBorder
        label="검사 설문지 선택"
        placeholder="선택사항 입니다."
        onChange={(v) => {
          setHairQuestionnaireId(v);
          setHairQuestionnaireAnswer("0");
        }}
        data={questionnaires}
        value={hairQuestionnaireId}
        className={classes.form}
      />
       {Boolean(hairQuestionnaireId) && (
        <FormControl component="fieldset" className={classes.form}>
          <h3>검사 설문지 답변</h3>
          <RadioGroup
            color="primary"
            aria-label="type"
            name="types"
            value={hairQuestionnaireAnswer}
            onChange={(event) => {
              setHairQuestionnaireAnswer(event.target.value);
            }}
          >
            <FormControlLabel
              value="1"
              control={<Radio color="primary" />}
              label="예"
            />
            <FormControlLabel
              value="0"
              control={<Radio color="primary" />}
              label="아니요"
            />
          </RadioGroup>
        </FormControl>
      )}
      
      */}
      <MultiLangForm
        onDataChange={setLangData}
        ref={langFormRef}
        forms={[
          {
            type: "input",
            label: "내용 요약",
            maxLength: 100,
            key: "summary",
            required: true,
          },
          {
            type: "multi",
            label: "내용",
            key: "description",
            maxLength: 1000,
            required: true,
          },
        ]}
        data={langData}
      />
    </DetailLayout>
  );
}

const useStyle = makeStyles({
  form: {
    marginBottom: "20px",
  },
});
/*
const RecommendationTypes = ({ classes, onChange, value }) => {
  return (
    <FormControl component="fieldset" className={classes.form}>
      <h3>유형</h3>
      <RadioGroup
        color="primary"
        aria-label="type"
        name="types"
        value={value}
        onChange={(event) => {
          onChange(event.target.value);
        }}
      >
        <FormControlLabel
          value="R"
          control={<Radio color="primary" />}
          label="추천사항"
        />
        <FormControlLabel
          value="P"
          control={<Radio color="primary" />}
          label="주의사항"
        />
        <FormControlLabel
          value="D"
          control={<Radio color="primary" />}
          label="위험사항"
        />
      </RadioGroup>
    </FormControl>
  );
};
*/
