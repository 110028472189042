import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { formatTime, formatNum } from "../../libs/utils";

//data [cells<[]>.[].[]]
//cells [{value, render, label}]
const Cells = ({ data, label, className }) => {
  const classes = useStyle();
  const [labelWidth, setLabelWidth] = useState(0);
  const handleLabelRef = (ref) => {
    if (!ref) {
      return;
    }

    if (labelWidth < ref.offsetWidth) {
      setLabelWidth(ref.offsetWidth + 30);
    }
  };

  return (
    <div className={classes.root + " " + className}>
      {label && <span className={classes.title}>{label}</span>}

      <div className={classes.wrap}>
        {Array.isArray(data) &&
          data.map((cells, index) => {
            return (
              <div className={classes.cells} key={index}>
                {cells.map((cell, cellIndex) => {
                  return (
                    <div className={classes.cell} key={cellIndex}>
                      <div
                        style={{
                          width: labelWidth ? `${labelWidth}px` : "auto",
                        }}
                        ref={handleLabelRef}
                        className={classes.label}
                      >
                        <span>{cell.label}</span>
                      </div>
                      <div
                        onClick={cell.onClick}
                        style={{ cursor: cell.onClick ? "pointer" : "normal" }}
                        className={classes.value}
                      >
                        <span>
                          {cell.type === "datetime"
                            ? formatTime(cell.value, "YYYY-MM-DD HH:mm")
                            : cell.type === "date"
                            ? formatTime(cell.value, "YYYY-MM-DD")
                            : cell.type === "number"
                            ? formatNum(cell.value)
                            : cell.type === "boolean"
                            ? cell.value === 1
                              ? "O"
                              : "X"
                            : cell.type === "price"
                            ? formatNum(cell.value) + "원"
                            : typeof cell.value === "string" &&
                              cell.value.includes("\n")
                            ? cell.value
                                .split("")
                                .map((x) => (x === "\n" ? <br /> : x))
                            : cell.value}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
      </div>
    </div>
  );
};

const useStyle = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "stretch",
  },
  wrap: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "stretch",
    borderLeft: "1px solid rgba(0,0,0,0.13)",
    borderTop: "1px solid rgba(0,0,0,0.13)",
  },
  cells: {
    display: "flex",
    alignSelf: "stretch",
    alignItems: "stretch",
    borderBottom: "1px solid rgba(0,0,0,0.13)",
  },
  cell: {
    display: "flex",
    alignItems: "stretch",
    flex: 1,
    borderRight: "1px solid rgba(0,0,0,0.13)",
  },
  label: {
    padding: "12px 12px",
    borderRight: "1px solid rgba(0,0,0,0.13)",
    backgroundColor: "rgb(245,245,245)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  value: {
    padding: "12px 12px",
    display: "flex",
    flex: 1,
    alignItems: "center",
  },
  title: {
    fontSize: "15px",
    marginBottom: "5px",
    fontWeight: "bold",
  },
});

export default Cells;
