import React from "react";

import { dialogOpenMessage } from "../dialog/DialogActions";
import { requestGet, requestPost } from "../../libs/network";
import api from "../../libs/api";
import { getLangLabel } from "../../libs/utils";
import consts from "../../libs/consts";

export const userActionType = {
  init: "user/init",
  clear: "clear",
};

export const userSignOut = (dispatch) => {
  localStorage.removeItem(consts.loginTokenKey);
  dispatch({ type: userActionType.clear });
};

export const userInit = async (dispatch) => {
  let signed = false;
  let admin = {};
  const token = localStorage.getItem(consts.loginTokenKey);
  if (token) {
    try {
      admin = await requestPost({
        url: api.verifyToken,
        body: {
          token,
        },
      });
      signed = true;
    } catch (error) {
      localStorage.removeItem(consts.loginTokenKey);
      dispatch(
        dialogOpenMessage({
          message: (
            <span>
              토큰검증 실패하였습니다.
              <br />
              다시 로그인해주세요.
            </span>
          ),
        })
      );
    }
  }
  dispatch({ type: userActionType.init, signed, admin });
};
