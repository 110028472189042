import React, { useRef, useState, useEffect } from "react";
import {
  makeStyles,
  TextField,
  IconButton,
  Paper,
  Button,
} from "@material-ui/core";
import TableItem from "./TableItem";
import TableHeader from "./TableHeader";
import { Search } from "@material-ui/icons";
import TablePaging from "./TablePaging";
import { useHistory, withRouter } from "react-router-dom";
import queryString from "query-string";
import { getQueryObj } from "../../libs/utils";
import TableDateButtons from "./TableDateButtons";
import TableFilterButtons from "./TableFilterButtons";

const Table = ({
  history,
  location,
  match,
  onFetch,
  columns,
  searchPlaceholder,
  title,
  data,
  maxLength = 0,
  onRowClick,
  onDataChange,
  radio,
  buttons,
  dateFilter = true,
  filters,
  filterComponent,
  effects,
}) => {
  const classes = useStyle();
  const [headerMounted, setHeaderMounted] = useState(false);
  const [actionSize, setActionSize] = useState({ width: 0, height: 0 });
  const his = useHistory();
  const query = getQueryObj(his.location);
  const keyword = query.keyword;
  const [tv, setv] = useState("");
  let timeRef = useRef();

  const setKeyword = (v) => {
    if (timeRef.current) {
      clearTimeout(timeRef.current);
    }
    timeRef.current = setTimeout(() => {
      history.replace({
        pathname: location.pathname,
        search: queryString.stringify({
          ...query,
          keyword: v,
        }),
      });
    }, 500);
  };

  useEffect(() => {
    if (query.page) {
      onFetch();
    }
    setv(query.keyword);
  }, [
    query.limit,
    query.page,
    query.keyword,
    query.inquirytype,
    query.dateEnd,
    query.dateStart,
    query.type,
    query.hairLevelCategoryId,
    query.method,
  ]);

  const handleTableItemClick = (item) => (event) => {
    onRowClick(item);
  };

  const handleSearch = () => {
    history.replace({
      pathname: location.pathname,
      search: queryString.stringify({
        ...query,
        keyword,
        page: "1",
      }),
    });
  };

  return (
    <Paper className={classes.root}>
      <div className={classes.header}>
        <span className={classes.title}>{title}</span>
        <div className={classes.searchBar}>
          <TextField
            value={tv}
            placeholder={searchPlaceholder}
            onBlur={handleSearch}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                history.replace({
                  pathname: location.pathname,
                  search: queryString.stringify({
                    ...query,
                    keyword: tv,
                    page: "1",
                  }),
                });
              }
            }}
            onChange={(e) => {
              setv(e.target.value);
              setKeyword(e.target.value);
              if (e.target.value === "") {
                delete query.keyword;
                history.replace({
                  pathname: location.pathname,
                  search: queryString.stringify({
                    ...query,
                    page: "1",
                  }),
                });
              }
            }}
            type="text"
            className={classes.search}
            variant="standard"
          />
          <IconButton
            onClick={() => {
              history.replace({
                pathname: location.pathname,
                search: queryString.stringify({
                  ...query,
                  keyword: tv,
                  page: "1",
                }),
              });
            }}
          >
            <Search />
          </IconButton>
        </div>
      </div>
      {dateFilter && (
        <TableDateButtons
          dateStart={query.dateStart}
          onClick={(data) => {
            if (data) {
              history.replace({
                pathname: location.pathname,
                search: queryString.stringify({
                  ...query,
                  ...data,
                  page: "1",
                }),
              });
            } else {
              delete query.dateStart;
              delete query.dateEnd;
              history.replace({
                pathname: location.pathname,
                search: queryString.stringify({
                  ...query,
                  page: "1",
                }),
              });
            }
          }}
        />
      )}

      {filters &&
        filters.map(({ key, data }, index) => {
          return (
            <TableFilterButtons
              field={key}
              history={history}
              location={location}
              data={data}
              key={index.toString()}
              query={query}
            />
          );
        })}
      {filterComponent}

      {
        //toolbar
      }
      <div className={classes.toolbar}>
        <span className={classes.maxLength}>
          검색 건수 <span>{maxLength}</span>
        </span>

        <div className={classes.buttons}>
          {buttons &&
            buttons.map((x, i) => (
              <Button
                color="primary"
                key={i.toString()}
                onClick={x.onClick}
                className={x.className}
                variant="contained"
                style={{ marginLeft: i > 0 ? 16 : 0 }}
              >
                {x.label}
              </Button>
            ))}
        </div>
      </div>

      <TableHeader
        onDidMount={() => {
          setHeaderMounted(true);
        }}
        actionSize={actionSize}
        columns={columns}
      />
      {headerMounted &&
        data &&
        data.map((x, i) => (
          <TableItem
            no={
              parseInt(query.page - 1) * query.limit + (i + 1) //   maxLength - i - (parseInt(query.page) - 1) * query.limit
            }
            onActionSize={(w, h) => {
              if (actionSize.width === 0 && actionSize.height === 0) {
                setActionSize({ width: w, height: h });
              }
            }}
            onClick={onRowClick && handleTableItemClick(x)}
            onRadioChange={
              radio &&
              ((v) => {
                onDataChange &&
                  onDataChange([
                    ...data.map((y, z) => {
                      if (i === z) {
                        y.radio = v;
                      } else {
                        y.radio = false;
                      }
                      return y;
                    }),
                  ]);
              })
            }
            columns={columns}
            data={x}
            key={i.toString()}
          />
        ))}

      <TablePaging
        match={match}
        history={history}
        location={location}
        maxLength={maxLength}
      />
    </Paper>
  );
};

const useStyle = makeStyles({
  search: {
    flex: 1,
  },
  root: {
    padding: "16px",
    display: "flex",
    flexDirection: "column",
  },
  header: {
    display: "flex",
    alignItems: "center",
    alignSelf: "stretch",
    justifyContent: "space-between",
  },

  title: {
    fontSize: "20px",
    fontWeight: "bold",
  },
  maxLength: {
    "& span": {
      color: "#f50057",
      fontWeight: "bold",
      fontSize: "17px",
    },
  },
  searchBar: {
    display: "flex",
    minWidth: "30%",
    alignItems: "center",
  },
  toolbar: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "50px",
    justifyContent: "space-between",
    marginBottom: "25px",
  },
  butttons: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
});

export default withRouter(Table);
