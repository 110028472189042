import React, { useEffect, useState, useRef } from "react";
import MultiLangForm from "../../components/multi-lang-form/MultiLangForm";
import { makeStyles } from "@material-ui/core";
import FileSelector from "../../components/file-selector/FileSelector";
import { useDispatch } from "react-redux";
import DetailLayout from "../../layouts/detail/DetailLayout";
import { dialogOpenError } from "../../redux/dialog/DialogActions";
import {
  requestPost,
  requestFile,
  requestGet,
  requestDelete,
} from "../../libs/network";
import api from "../../libs/api";
import { setFormArray } from "../../libs/utils";

const NotificationDetail = ({ history, location }) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const langFormRef = useRef();
  const [notificationId, setNotificationId] = useState(null);
  const [langData, setLangData] = useState();

  useEffect(() => {
    if (location.state?.notificationId) {
      setLangData(location.state.translations);
      setNotificationId(location.state?.notificationId);
    }
  }, []);

  const handleConfirm = async () => {
    if (langFormRef.current.check()) {
      try {
        await requestPost({
          url: api.insertNotification,
          body: {
            translations: langData,
          },
          method: "post",
        });
        history.goBack();
      } catch (error) {
        dispatch(dialogOpenError(error));
      }
    }
  };

  const deleteNotification = async () => {
    try {
      await requestDelete({ url: api.deleteNotification(notificationId) });
      history.goBack();
    } catch (error) {
      dispatch(dialogOpenError(error));
    }
  };

  return (
    <DetailLayout
      onDelete={notificationId && deleteNotification}
      title={notificationId ? "알림사항 상세" : "알림사항 등록"}
      buttonConfirmLabel="등록"
      buttonConfirm={!Boolean(notificationId)}
      onConfirm={handleConfirm}
    >
      <MultiLangForm
        readOnly={Boolean(notificationId)}
        onDataChange={setLangData}
        ref={langFormRef}
        forms={[
          {
            type: "input",
            label: "제목",
            key: "title",
            maxLength: 50,
            required: true,
          },
          {
            type: "multi",
            label: "메시지",
            key: "message",
            maxLength: 200,
            required: true,
          },
        ]}
        data={langData}
      />
    </DetailLayout>
  );
};

const useStyle = makeStyles({});
export default NotificationDetail;
