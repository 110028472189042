import React, { useState } from "react";
import { makeStyles, IconButton } from "@material-ui/core";
import { isImage, getFileUri } from "../../libs/utils";
import { Close } from "@material-ui/icons";
const FileSelectorItem = ({ name, uri, onDelete, path }) => {
  const classes = useStyle();
  const [max, setMax] = useState();
  return (
    <div
      ref={(ref) => {
        if (ref && !max) {
          setMax({
            maxWidth: ref.clientWidth - 16,
            maxHeight: ref.clientHeight - 16,
            width: ref.clientWidth - 16,
            height: ref.clientHeight - 16,
          });
        }
      }}
      className={classes.root}
    >
      <div className={classes.item}>
        {isImage(path || name) && max && (
          <img
            style={max ? max : {}}
            src={path ? getFileUri(path) || uri : uri}
            className={classes.img}
          />
        )}
        <div onClick={onDelete} className={classes.close}>
          <Close color="secondary" />
        </div>
      </div>
    </div>
  );
};

const useStyle = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "20%",
    height: "100%",
    padding: "0px 10px 10px 0px",
  },
  item: {
    position: "relative",
    border: "1px solid rgba(0,0,0,0.13)",
    borderRadius: "5px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  img: {
    flex: 1,
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  close: {
    cursor: "pointer",
    position: "absolute",
    top: 0,
    right: 0,
  },
});

export default FileSelectorItem;
