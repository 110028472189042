import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";
import queryString from "query-string";
const Paging = ({ history, location, maxLength = 0, rowPerPage = 0 }) => {
  const query = queryString.parse(location.search);

  useEffect(() => {
    if (!query.page) {
      history.replace({
        pathname: location.pathname,
        search: queryString.stringify({
          ...query,
          page: "1",
          limit: "10",
        }),
      });
    }
  }, [query.page]);

  if (!query.page || maxLength === 0) {
    return null;
  }
  return (
    <Pagination
      onChange={(e, page) => {
        history.push({
          pathname: location.pathname,
          search: queryString.stringify({
            ...query,
            page,
          }),
        });
      }}
      page={parseInt(query.page)}
      count={Math.ceil(parseInt(maxLength) / parseInt(rowPerPage))}
      showFirstButton
      showLastButton
    />
  );
};

export default withRouter(Paging);
