export const dialogActionType = {
  openMesasge: "dialog/openMessage",

  close: "dialog/close",
};

export const dialogOpenError = (error) => (dispatch) => {
  dispatch(
    dialogOpenMessage({
      message:
        error.message ||
        (typeof error === "object" ? JSON.stringify(error) : String(error)),
    })
  );
};

export const dialogOpenLangAlert = (dispatch) => {
  dispatch(
    dialogOpenMessage({
      message: "입력하신 다국어를 먼저 저장해주세요.",
    })
  );
};

export const dialogOpenMessage = ({ message, onClick, close = true }) => (
  dispatch
) => {
  dispatch({
    type: dialogActionType.openMesasge,
    message,
    onClick,
    close,
  });
};
export const dialogClose = (dispatch) => {
  dispatch({
    type: dialogActionType.close,
  });
};
