import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useDispatch } from "react-redux";
import { dialogOpenError } from "../../redux/dialog/DialogActions";
import { requestPost } from "../../libs/network";
import api from "../../libs/api";
import consts from "../../libs/consts";
import { userInit } from "../../redux/user/UserActions";
import { makeStyles } from "@material-ui/core";
import LockImage from "../../assets/images/lock.png";
import LogoImage from "../../assets/images/logo.png";
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="http://kilin.tech/">
        Kilin Tech
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function SignIn() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleChange = (name) => ({ target: { value } }) => {
    if (name === "username") {
      setUsername(value);
    } else if (name === "password") {
      setPassword(value);
    }
  };

  const signIn = async () => {
    try {
      if (!username || !password) {
        throw "아이디와 비밀번호를 확인해주세요.";
      }
      const { token } = await requestPost({
        url: api.signIn,
        body: {
          username,
          password,
        },
      });
      localStorage.setItem(consts.loginTokenKey, token);
      dispatch(userInit);
    } catch (error) {
      dispatch(dialogOpenError(error));
    }
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image}>
        <img alt="backgrond" src={LogoImage} className={classes.logo} />
      </Grid>
      <Grid item xs={12} sm={8} md={5} square className={classes.paper}>
        <img alt="lock" src={LockImage} className={classes.avatar} />
        <Typography className={classes.label} component="h1" variant="h5">
          관리자 로그인
        </Typography>
        <div className={classes.div} noValidate>
          <Paper className={classes.inputPaper}>
            <input
              autoFocus
              value={username}
              onChange={handleChange("username")}
              placeholder="Username"
            />
          </Paper>
          <Paper className={classes.inputPaper}>
            <input
              value={password}
              onChange={handleChange("password")}
              variant="outlined"
              margin="normal"
              fullWidth
              placeholder="Password"
              name="password"
              type="password"
              id="password"
              autoComplete="current-password"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  signIn();
                }
              }}
            />
          </Paper>
          <Button
            type="submit"
            variant="contained"
            color="default"
            onClick={signIn}
            className={classes.submit}
          >
            로그인
          </Button>
          <Box mt={5}>
            <Copyright />
          </Box>
        </div>
      </Grid>
    </Grid>
  );
}
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundColor: "#FFC641",
    display: "flex",
    position: "relative",
    flexDirection: "column",
    alignItems: "center",
    backgroundPosition: "center",
    height: "100%",
    justifyContent: "space-between",
  },
  logo: {
    width: "200px",
    objectFit: "conatain",
    marginTop: "20%",
  },
  paper: {
    padding: theme.spacing(12, 20),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#F9F9F9",
    justifyContent: "center",
  },
  avatar: {
    objectFit: "contain",
    width: "30px",
    height: "30px",
  },
  div: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
  },
  submit: {
    minWidth: "50%",
    padding: "10px 16px",
    margin: "50px 0px",
    color: "#fff",
    alignSelf: "center",
    backgroundColor: "#FEBE10",
  },
  label: {
    marginTop: "8px",
    color: "#FEBE10",
    marginBottom: "30px",
    fontWeight: "bold",
  },
  inputPaper: {
    marginTop: "16px",
    "& input": {
      width: "100%",
      padding: "16px",
      "&:focus": { outline: "none" },
      borderRadius: "5px",
      border: "none",
    },
  },
}));
