import React from "react";
import { makeStyles } from "@material-ui/core";
import FileSelectorAdd from "./FileSelectorAdd";
import FileSelectorItem from "./FileSelectorItem";

const FileSelector = ({
  acceptImage,
  acceptDoc,
  label,
  files,
  onChange,
  maxLength,
  onAdd,
  onDelete,
  readOnly,
  className,
}) => {
  const classes = useStyle();
  return (
    <div className={classes.root + " " + className}>
      <div className={classes.header}>
        <h3>{label}</h3>
      </div>
      <div className={classes.files}>
        {files.map((x, i) => {
          return (
            <FileSelectorItem
              {...x}
              key={i.toString()}
              onDelete={() => {
                onDelete(x, i);
              }}
            />
          );
        })}
        {!readOnly && files.length < maxLength && (
          <FileSelectorAdd acceptImage acceptDoc onAdd={onAdd} />
        )}
      </div>
    </div>
  );
};

const useStyle = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  files: {
    display: "flex",
    alignSelf: "stretch",
    flexDirection: "row",
    height: "200px",
    alignItems: "center",
  },
  header: {
    marginBottom: "3px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
});

export default FileSelector;
