import React, { useEffect, useState } from "react";
import {
  makeStyles,
  TextField,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import DetailLayout from "../../layouts/detail/DetailLayout";
import { dialogOpenError } from "../../redux/dialog/DialogActions";
import { requestPost, requestDelete, requestPut } from "../../libs/network";
import api from "../../libs/api";
import Cells from "../../components/cells/Cells";

const MemberAdminDetail = ({ history, location }) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const dataReducer = useSelector((s) => s.data, []);
  const [adminId, setAdminId] = useState(null);
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [memo, setMemo] = useState("");
  const [passwordSecure, setPasswordSecure] = useState(true);
  const [permission, setPermission] = useState([]);

  useEffect(() => {
    if (location.state?.adminId) {
      setAdminId(location.state?.adminId);
      setUsername(location.state.username);
      setName(location.state.name);
      setMemo(location.state.memo);
      setPermission(
        location.state.permission ? location.state.permission.split("&") : []
      );
    }
  }, []);

  const handleConfirm = async () => {
    try {
      if (!name) {
        throw "이름을 입력하세요.";
      } else if (!username) {
        throw "아이디를 입력하세요.";
      } else if (!password && !adminId) {
        throw "비밀번호를 입력하세요.";
      } else if (permission.length < 1) {
        throw "권한을 1개이상 선택해주세요.";
      }
      if (adminId) {
        const columns = [];
        const values = [];
        if (name && name !== location.state.name) {
          columns.push("name");
          values.push(name);
        }
        if (username && username !== location.state.username) {
          columns.push("username");
          values.push(username);
        }
        if (memo && memo !== location.state.memo) {
          columns.push("memo");
          values.push(memo);
        }
        if (password) {
          columns.push("password");
          values.push(password);
        }
        if (location.state.permission !== permission.join("&")) {
          columns.push("permission");
          values.push(permission);
        }
        await requestPut({
          url: api.putAdmin(adminId),
          body: {
            columns,
            values,
          },
        });
      } else {
        await requestPost({
          url: api.insertAdmin,
          body: {
            name,
            permission: permission,
            username,
            password,
            memo,
          },
        });
      }

      history.goBack();
    } catch (error) {
      dispatch(dialogOpenError(error));
    }
  };

  const deleteAdmin = async () => {
    try {
      await requestDelete({ url: api.deleteAdmin(adminId) });
      history.goBack();
    } catch (error) {
      dispatch(dialogOpenError(error));
    }
  };

  const handleInputChange = (name) => (event) => {
    const {
      target: { value },
    } = event;
    if (name === "name") {
      if (value.length >= 100) {
        return;
      }
      setName(value);
    } else if (name === "username") {
      if (value.length >= 255) {
        return;
      }
      setUsername(value);
    } else if (name === "password") {
      if (value.length >= 100) {
        return;
      }
      setPassword(value);
    } else if (name === "memo") {
      if (value.length >= 1000) {
        return;
      }
      setMemo(value);
    }
  };

  return (
    <DetailLayout
      onDelete={adminId && deleteAdmin}
      buttonConfirmLabel={adminId ? "수정" : "등록"}
      title={adminId ? "관리자회원 수정" : "관리자회원 등록"}
      onConfirm={handleConfirm}
      title={"관리자 상세"}
    >
      <TextField
        value={name}
        variant="outlined"
        onChange={handleInputChange("name")}
        label="관리자 이름"
      />
      <TextField
        value={username}
        className={classes.textfield2}
        variant="outlined"
        onChange={handleInputChange("username")}
        label="아이디"
      />
      <TextField
        type={!passwordSecure ? "password" : "text"}
        value={password}
        className={classes.textfield2}
        variant="outlined"
        onChange={handleInputChange("password")}
        label={Boolean(adminId) ? "새로운 비밀번호" : "비밀번호"}
      />
      {Boolean(adminId) && (
        <span className={classes.label}>입력하신 비밀번호로 변경됩니다.</span>
      )}

      <FormControlLabel
        className={classes.passwordSecure}
        control={
          <Checkbox
            color="secondary"
            checked={passwordSecure}
            onChange={({ target: { checked } }) => {
              setPasswordSecure(checked);
            }}
          />
        }
        label="비밀번호 표시"
      />

      <h3>권한 관리</h3>
      <FormGroup className={classes.formGroup} row>
        {dataReducer.permissions.map((x, i) => {
          return (
            <FormControlLabel
              onChange={({ target: { checked } }) => {
                if (permission.includes(x.value)) {
                  setPermission([...permission.filter((z) => x.value !== z)]);
                } else {
                  setPermission([...permission, x.value]);
                }
              }}
              control={
                <Checkbox
                  color="primary"
                  checked={permission.includes(x.value)}
                />
              }
              label={x.label}
            />
          );
        })}
      </FormGroup>

      <TextField
        value={memo}
        className={classes.textfield2 + " " + classes.multiInput}
        variant="outlined"
        onChange={handleInputChange("memo")}
        label="메모"
        multiline
      />

      {Boolean(adminId) && (
        <Cells
          data={[
            [
              {
                label: "등록일",
                value: location.state.createdAt,
                type: "datetime",
              },
              {
                label: "최근 로그인",
                value: location.state.lastSignedAt,
                type: "datetime",
              },
            ],
          ]}
          className={classes.cells}
        />
      )}
    </DetailLayout>
  );
};

const useStyle = makeStyles({
  textfield2: {
    marginTop: "30px",
  },
  multiInput: {
    "& textarea": {
      height: "300px !important",
    },
  },
  passwordSecure: {
    alignSelf: "flex-end",
  },
  label: {
    alignSelf: "flex-end",
    color: "gray",
  },
  cells: {
    marginTop: "50px",
  },
  formGroup: {
    marginBottom: "30px",
  },
});
export default MemberAdminDetail;
