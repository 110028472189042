import React from "react";
import { makeStyles, Button } from "@material-ui/core";
import { getMomentBeforeDay, getToday } from "../../libs/utils";

const TableDateButtons = ({ onClick, dateStart }) => {
  const classes = useStyle();
  return (
    <div className={classes.root}>
      {[
        {
          label: "전체기간",
        },
        { label: "오늘", value: getToday() },
        { label: "1개월", value: getMomentBeforeDay(1, "months") },
        { label: "3개월", value: getMomentBeforeDay(3, "months") },
        { label: "6개월", value: getMomentBeforeDay(6, "months") },
        { label: "1년", value: getMomentBeforeDay(1, "years") },
      ].map((x, i) => {
        const isSelected = x.value === dateStart ? classes.buttonsS : "";
        return (
          <Button
            onClick={() => {
              onClick(x.value && { dateStart: x.value, dateEnd: getToday() });
            }}
            className={classes.button + "  " + isSelected}
            key={i.toString()}
            variant="outlined"
          >
            {x.label}
          </Button>
        );
      })}
    </div>
  );
};

const useStyle = makeStyles({
  root: {
    marginTop: "8px",
    display: "flex",
    alignItems: "center",
    alignSelf: "stretch",
    justifyContent: "flex-end",
  },
  button: {
    marginLeft: "8px",
  },
  buttonsS: {
    borderColor: "#3f51b5",
  },
});

export default React.memo(TableDateButtons);
