import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DetailLayout from "../../layouts/detail/DetailLayout";
import MultiLangForm from "../../components/multi-lang-form/MultiLangForm";
import { requestDelete, requestPost, requestPut } from "../../libs/network";
import consts from "../../libs/consts";
import { dialogOpenError } from "../../redux/dialog/DialogActions";
import Radios from "../../components/radios/Radios";
import { Button, makeStyles } from "@material-ui/core";

export default function QnaDetail({ location, history }) {
  const dispatch = useDispatch();
  const classes = useStyle();
  const [qnaId, setQnaId] = useState(null);
  const langFormRef = useRef();
  const { adminId } = useSelector((s) => s.user, []);
  const [langData, setLangData] = useState();

  useEffect(() => {
    if (location.state?.qnaId) {
      setQnaId(location.state.qnaId);
      setLangData(location.state.translations);
    }
  }, []);

  const handleConfirm = async () => {
    if (langFormRef.current.check()) {
      try {
        if (qnaId) {
          await requestPut({
            url: consts.apiBasicUrl + "/qnas/" + qnaId,
            body: {
              translations: langData,
            },
          });
        } else {
          await requestPost({
            url: consts.apiBasicUrl + "/qnas",
            body: {
              adminId,
              translations: langData,
            },
          });
        }
        history.goBack();
      } catch (error) {
        dispatch(dialogOpenError(error));
      }
    }
  };

  const deleteItem = async () => {
    try {
      await requestDelete({
        url: consts.apiBasicUrl + "/qnas/" + qnaId,
      });
      history.goBack();
    } catch (error) {
      dispatch(dialogOpenError(error));
    }
  };

  return (
    <DetailLayout
      onDelete={qnaId && deleteItem}
      buttonConfirmLabel={qnaId ? "수정" : "등록"}
      title={qnaId ? "모발상식 Q&A 수정" : "모발상식 Q&A 등록"}
      onConfirm={handleConfirm}
    >
      <MultiLangForm
        onDataChange={setLangData}
        ref={langFormRef}
        forms={[
          {
            type: "input",
            label: "질문",
            key: "question",
            maxLength: 100,
            required: true,
          },
          {
            type: "multi",
            label: "답변",
            key: "answer",
            maxLength: 500,
            required: true,
          },
        ]}
        data={langData}
      />
    </DetailLayout>
  );
}

const useStyle = makeStyles({
  form: {
    marginTop: "40px",
  },
  button: { marginLeft: "16px" },
  tabsSencond: {
    borderBottom: "1px solid gray",
  },
  button2: {
    margin: "15px 0px",
  },
});
