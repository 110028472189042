import React, { useState } from "react";
import Table from "../../components/table/Table";
import routes from "../../libs/routes";
import { useDispatch } from "react-redux";
import { dialogOpenError } from "../../redux/dialog/DialogActions";
import { requestGet, requestPatch } from "../../libs/network";
import { getQueryObj } from "../../libs/utils";
import consts from "../../libs/consts";
import { Button } from "@material-ui/core";

export default function Qna({ location, history }) {
  const [maxLength, setMaxLength] = useState(0);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const query = getQueryObj(location);

  const fetchQuestionnaires = async () => {
    try {
      const { qnas, maxLength } = await requestGet({
        url: consts.apiBasicUrl + "/qnas",
        query,
      });
      setMaxLength(maxLength);
      setData([...qnas]);
    } catch (error) {
      dispatch(dialogOpenError(error));
    }
  };

  const handleSort = (name, { qnaId }) => async () => {
    try {
      await requestPatch({
        url: consts.apiBasicUrl + "/qnas/" + qnaId,
        body: {
          action: name,
        },
      });
      fetchQuestionnaires();
    } catch (error) {
      dispatch(dialogOpenError(error));
    }
  };

  return (
    <div>
      <Table
        onFetch={fetchQuestionnaires}
        title="모발상식 Q&A"
        maxLength={maxLength}
        data={data}
        searchPlaceholder="질문, 답변"
        onRowClick={(item) => {
          history.push(routes.qnaDetail, item);
        }}
        columns={[
          { label: "앱 노출 순서", key: "sort", wrap: true },
          { label: "질문", key: "question", flex: 3, left: true },
          {
            label: "순서 변경",
            render: (_) => (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <Button
                  onClick={handleSort("up", _)}
                  color="primary"
                  style={{ marginRight: 16 }}
                  variant="outlined"
                >
                  올리기
                </Button>
                <Button
                  onClick={handleSort("down", _)}
                  color="secondary"
                  variant="outlined"
                >
                  내리기
                </Button>
              </div>
            ),
          },
        ]}
        buttons={[
          {
            label: "등록",
            onClick: () => {
              history.push(routes.qnaDetail);
            },
          },
        ]}
      />
    </div>
  );
}
