import { dataActionType } from "./DataActions";

const initData = {
  fetched: false,
  langs: [],
  permissions: [],
  productCategories: [],
  levels: [],
  langCodeCategoryId: null,
};

export default function data(state = initData, action) {
  switch (action.type) {
    case dataActionType.fetch:
      return {
        ...state,
        permissions: action.permissions,
        langs: action.langs,
        productCategories: action.productCategories,
        levels: action.levels,
        langCodeCategoryId: action.langCodeCategoryId,
        fetched: true,
      };
    default:
      return state;
  }
}
