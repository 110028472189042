import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core";

const TableHeader = ({ columns, actionSize, onDidMount }) => {
  const classes = useStyle();

  useEffect(() => {
    onDidMount();
  }, [onDidMount]);
  return (
    <div className={classes.root}>
      <div
        id="no"
        className={classes.item}
        style={{ paddingLeft: "16px", paddingRight: "16px" }}
      >
        순번
      </div>

      {(Boolean(actionSize.width) || Boolean(actionSize.height)) && (
        <div style={actionSize} />
      )}
      {columns.map((x, i) => {
        return (
          <div
            id={`${x.label}-${i}`}
            className={classes.item}
            key={i.toString()}
            style={
              x.wrap
                ? { paddingLeft: "16px", paddingRight: "16px" }
                : { flex: x.flex || 1 }
            }
          >
            {x.label}
          </div>
        );
      })}
    </div>
  );
};

const useStyle = makeStyles({
  root: {
    backgroundColor: "rgb(240,240,240)",
    border: "1px solid rgba(0,0,0,0.13)",
    display: "flex",
    flexDirection: "row",
    alignSelf: "stretch",
  },
  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "16px",
    fontWeight: "bold",
    borderRight: "1px solid rgba(0,0,0,0.23)",
    "&:last-child": { borderRight: "0px" },
  },
});

export default TableHeader;
